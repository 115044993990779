import React from 'react';

export default function Form(props: any) {
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.onSubmit();
  };

  const disableSubmitOnEnter = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.key === 'Enter' && props.disableSubmitOnEnter) {
      e.preventDefault();
    }
  };

  return (
    <form autoComplete="off" onSubmit={handleSubmit} onKeyDown={disableSubmitOnEnter} noValidate style={props.style}>
      {props.children}
    </form>
  );
}
