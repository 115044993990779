import React, { useEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { Check, Close } from '@mui/icons-material';

interface PasswordHelperTextProps {
  password: string;
  isPasswordValid: (val: boolean) => void;
}

// Regular expressions for password validation
const allowedCharacters = /^[A-Za-z0-9!@#$%^&*\-_+=[\]{}|\\:‘,?/`~“() ;]+$/;
const lowerCase = /[a-z]/;
const upperCase = /[A-Z]/;
const number = /\d/; // Equivalent to /[0-9]/
const symbol = /[!@#$%^&*\-_+=[\]{}|\\:‘,?/`~“() ;]/;
const disallowedPattern = /\.@/; // Check for '.' immediately before '@'

export const PasswordHelperText: React.FunctionComponent<PasswordHelperTextProps> = ({ password, isPasswordValid }: PasswordHelperTextProps) => {
  useEffect(() => {
    function checkPasswordValidity(password: string): boolean {
      // Check for allowed characters
      if (!testRegex(allowedCharacters, password)) {
        return false;
      }

      // Check for disallowed patterns
      if (testRegex(disallowedPattern, password)) {
        return false;
      }

      // Check password length
      if (!isLengthValid(password)) {
        return false;
      }

      // Check for at least 3 out of the 4 types of characters
      const types = [lowerCase, upperCase, number, symbol].filter(regex => regex.test(password)).length;
      return types >= 3;
    }

    isPasswordValid(checkPasswordValidity(password));
  }, [password, isPasswordValid]);

  function testRegex(regex: RegExp, str: string): boolean {
    return regex.test(str);
  }

  function isLengthValid(str: string): boolean {
    return str.length >= 8 && str.length <= 16;
  }

  return (
    <Box py={1}>
      <Grid item display="flex" gap="8px" alignItems="center">
        {isLengthValid(password) ? <Check color="success" /> : <Close color="error" />}
        <Typography fontSize="14px">8 and 16 characters in length</Typography>
      </Grid>
      <Grid item display="flex" gap="8px" alignItems="center">
        {testRegex(lowerCase, password) ? <Check color="success" /> : <Close color="error" />}
        <Typography fontSize="14px">Lowercase letters (a-z)</Typography>
      </Grid>
      <Grid item display="flex" gap="8px" alignItems="center">
        {testRegex(upperCase, password) ? <Check color="success" /> : <Close color="error" />}
        <Typography fontSize="14px">Uppercase letters (A-Z)</Typography>
      </Grid>
      <Grid item display="flex" gap="8px" alignItems="center">
        {testRegex(number, password) ? <Check color="success" /> : <Close color="error" />}
        <Typography fontSize="14px">Numbers (0-9)</Typography>
      </Grid>
      <Grid item display="flex" gap="8px" alignItems="center">
        {testRegex(symbol, password) ? <Check color="success" /> : <Close color="error" />}
        <Typography fontSize="14px">Symbols (! @ # $ % ^ & * - _  + = [ ] {} | : ‘ , ? / ` ~ “ ( ) ;)</Typography>
      </Grid>
    </Box>
  );
};

export default PasswordHelperText;
