import Module from '../../models/module';
import Segment from '@mui/icons-material/Segment';
import { lazyMinLoadTime } from '../../utils/codeSplitting';
import FeatureFlags from '../../launchDarkly/featureFlags';
import { mainPaths } from './components';
import Features from '../../rbac/features';

const modulePrefix = 'segmentation_';
const SegmentationModule = new Module({
  name: 'Segmentation',
  path: '/segmentation',
  modulePrefix: modulePrefix,
  component: lazyMinLoadTime(() => import('./components')),
  icon: <Segment />,
  permissions: {
    ldFlag: FeatureFlags.Segmentation,
    features: [Features.Segmentation]
  },
  mainPaths: mainPaths
});

export default SegmentationModule;
