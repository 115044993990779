import { Grid } from '@mui/material';
import { FallbackProps } from 'react-error-boundary';
import PageContentHeading from '../styled/PageContentHeading';
import ButtonWithWrapper from './controls/ButtonWithWrapper';
import { Home } from '@mui/icons-material/';

const ErrorBoundaryFallback: React.FunctionComponent<FallbackProps> = ({ error, resetErrorBoundary }) => {
  return (
    <div role="alert">
      <PageContentHeading variant="h5" id="app-error-title">
        Something went wrong
      </PageContentHeading>
      <Grid container>
        <Grid item xs={12}>
          <PageContentHeading component="div" variant="h6">
            {error.message}
          </PageContentHeading>
        </Grid>
        <Grid container justifyContent="left" alignItems="flex-start" xs={12}>
          <ButtonWithWrapper
            id="navigate-back-home-button"
            type="button"
            variant="outlined"
            color="primary"
            size="small"
            fullWidth={true}
            startIcon={<Home />}
            isWrapperMobileFullWidth={true}
            onClick={resetErrorBoundary}
          >
            Back To Home
          </ButtonWithWrapper>
        </Grid>
      </Grid>
    </div>
  );
};

export default ErrorBoundaryFallback;
