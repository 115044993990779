import React, { useEffect } from 'react';
import { Button, Card, CardActions, CardContent, CardHeader, Divider, InputAdornment, TextField } from '@mui/material';
import { Search } from '@mui/icons-material';
import { useFetchAllTenantsQuery } from '../modules/client-admin/api/ClientAdminTenantApiSlice';
import { SwitchTenantButton } from '../styled/SwitchTenantButton';
import { useSelector } from 'react-redux';
import { AccountInfoType, selectAccounts, selectCurrentAccount, selectIsGlobalAdmin } from '../auth/AccountInfoSlice';
import SavedTenantModel from '../modules/client-admin/models/SavedTenantModel';

interface TenantSwitcherProps {
  onSwitch: (tenantId: string) => void;
  onCancel: () => void;
}

interface TenantSelectItem {
  id: string | null;
  name: string;
}

export const TenantSwitcher: React.FunctionComponent<TenantSwitcherProps> = ({ onSwitch, onCancel }: TenantSwitcherProps) => {
  const [searchTerm, setSearchTerm] = React.useState<string>('');
  const [selectedTenant, setSelectedTenant] = React.useState<string>('');
  const isGlobalAdmin = useSelector(selectIsGlobalAdmin);
  const userAccounts = useSelector(selectAccounts);
  const currentAccount = useSelector(selectCurrentAccount);

  const { tenants } = useFetchAllTenantsQuery(undefined, {
    selectFromResult: ({ data }) => {
      if (!data) return { tenants: [] as TenantSelectItem[] };
      const filteredTenants = data.filter((tenant: SavedTenantModel) => {
        if (isGlobalAdmin) return true; // If Global Admin return all tenants
        const accessibleTenantIds = userAccounts?.map((account: AccountInfoType) => account.tenantId);
        if (accessibleTenantIds.includes(tenant.id)) return tenant; // If not Global Admin return only tenants that the user is a member of
        return false;
      });

      const tenants = filteredTenants.filter((tenant: SavedTenantModel) => {
        return tenant.name.toLowerCase().includes(searchTerm.toLowerCase());
      });

      const tenantSelectItem: TenantSelectItem[] = tenants.map((tenant: SavedTenantModel) => {
        return {
          id: tenant.id,
          name: tenant.name
        };
      });

      return { tenants: tenantSelectItem };
    }
  });

  useEffect(() => {
    if (currentAccount) {
      setSelectedTenant(currentAccount.tenantId);
    }
  }, [currentAccount]);

  const handleSwitch = () => {
    onSwitch(selectedTenant);
  };

  const handleSelect = (id: string) => {
    setSelectedTenant(id);
  };

  return (
    <Card id="TenantSwitcher">
      <CardHeader title="Tenant Switcher" sx={{ textAlign: 'center' }} />
      <Divider />
      <CardContent>
        <TextField
          autoFocus
          fullWidth
          label="Search tenant name"
          variant="outlined"
          onChange={e => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            )
          }}
        />
      </CardContent>
      <Divider />
      <CardContent sx={{ padding: 0, maxHeight: '20rem', overflowY: 'scroll' }}>
        {tenants.map((tenant: TenantSelectItem) => (
          <SwitchTenantRow key={tenant.id} tenant={tenant} onClick={handleSelect} disabled={tenant.id === selectedTenant} />
        ))}
      </CardContent>
      <Divider />
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <Button onClick={onCancel}>Cancel</Button>
        <Button disabled={!selectedTenant || selectedTenant === currentAccount.tenantId} onClick={handleSwitch}>
          Switch
        </Button>
      </CardActions>
    </Card>
  );
};

interface SwitchTenantRowProps {
  tenant: TenantSelectItem;
  onClick: (id: string) => void;
  disabled?: boolean;
}

const SwitchTenantRow: React.FunctionComponent<SwitchTenantRowProps> = ({ tenant, onClick, disabled }) => {
  return (
    <>
      <SwitchTenantButton
        key={tenant.id}
        disabled={disabled}
        fullWidth
        sx={{ height: '3rem', padding: '1rem' }}
        onClick={() => onClick(tenant.id as string)}
      >
        {tenant.name}
      </SwitchTenantButton>
      <Divider />
    </>
  );
};

export default TenantSwitcher;
