import Roles from './roles';
import Features from './features';

export const canViewBasedOnRoles = (userRoles: Array<string>, requiredRoles: Array<Roles> | undefined): boolean => {
  if (!requiredRoles || !requiredRoles.length) return true;
  if (!userRoles || !userRoles.length) return false;
  return requiredRoles.some(role => userRoles.includes(role));
};

export const canViewBasedOnFeatures = (userFeatures: Array<string>, requiredFeatures: Array<Features> | undefined): boolean => {
  if (!requiredFeatures || !requiredFeatures.length) return true;
  if (!userFeatures || !userFeatures.length) return false;
  return requiredFeatures.some(role => userFeatures.includes(role));
};
