import TenantModel from '../models/TenantModel';
import ReducerDefinition from '../../../interfaces/ReducerDefinition';
import { ClientAdminAction } from '../actions/clientAdmin';
import { ClientAdminActionType } from '../actions/enums';
import { ReduxInitAction } from '../../../typings';

export interface ClientAdminState {
  isFetching: boolean;
  tenants: TenantModel[];
  orgId: string;
  fetchError: any;
}

export const defaultClientAdminState: ClientAdminState = {
  isFetching: false,
  tenants: [],
  orgId: '',
  fetchError: ''
};

const clientAdminReducer: ReducerDefinition = {
  reducerName: 'tenants',
  reducerFunction: (state: ClientAdminState = defaultClientAdminState, action: ClientAdminAction | ReduxInitAction): ClientAdminState => {
    switch (action.type) {
      case ClientAdminActionType.FetchInit:
        return {
          ...defaultClientAdminState,
          isFetching: true
        } as ClientAdminState;
      case ClientAdminActionType.FetchSuccess:
        return {
          ...state,
          isFetching: false,
          tenants: action.payload
        } as ClientAdminState;
      case ClientAdminActionType.FetchError:
        return {
          ...state,
          isFetching: false,
          fetchError: action.payload
        } as ClientAdminState;
      case ClientAdminActionType.SaveSuccess:
        return {
          ...state,
          isFetching: false,
          tenants: [...state.tenants, action.payload]
        } as ClientAdminState;
      default:
        return state;
    }
  }
};

export default clientAdminReducer;
