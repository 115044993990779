import { createSlice } from '@reduxjs/toolkit';
import Roles from '../rbac/roles';
import FeatureModel from '../modules/user-admin/models/FeatureModel';
import { setCurrentTenantIdToSessionStorage } from '../utils/sessionStorage';

export interface AccountInfoState {
  accounts: AccountInfoType[];
  currentAccount: AccountInfoType;
  features: FeatureModel[];
}

export interface AccountInfoType {
  name?: string;
  picture?: string;
  email?: string;
  sub?: string;
  roles: string[];
  features: string[];
  uuid: string;
  tenantId: string;
  tenantName?: string;
  permissions?: string[];

  [key: string]: any;
}

const initialState: AccountInfoState = {
  accounts: [],
  currentAccount: {} as AccountInfoType,
  features: []
};

export const selectCurrentAccount = (state: any) => state.accountInfo.currentAccount as AccountInfoType;
export const selectAccounts = (state: any) => state.accountInfo.accounts;
export const selectIsCompanyTenant = (state: any) => state.accountInfo.currentAccount.tenantId === process.env.REACT_APP_AQ_TENANT_ID;
export const selectIsGlobalAdmin = (state: any) => state.accountInfo.currentAccount.roles?.includes(Roles.GlobalAdmin);
export const selectTenantName = (state: any) => state.accountInfo.currentAccount.tenantName;
export const selectFeatures = (state: any) => state.accountInfo.features;

export const AccountInfoSlice = createSlice({
  name: 'accountInfo',
  initialState,
  reducers: {
    setAccounts: (state, action) => {
      state.accounts = action.payload;
      state.currentAccount =
        action.payload.find((acct: AccountInfoType) => acct.tenantId === process.env.REACT_APP_AQ_TENANT_ID) || action.payload[0];
    },
    setCurrentAccount: (state, action) => {
      if (state.currentAccount?.roles?.includes(Roles.GlobalAdmin)) {
        state.currentAccount.tenantId = action.payload;
        setCurrentTenantIdToSessionStorage(action.payload);
      } else {
        const account = state.accounts.find(account => account.tenantId === action.payload) ?? state.accounts[0];
        setCurrentTenantIdToSessionStorage(account?.tenantId);
        state.currentAccount = account ?? ({} as AccountInfoType);
      }
    },
    setCurrentTenantName: (state, action) => {
      state.currentAccount.tenantName = action.payload;
    },
    setFeatures: (state, action) => {
      state.features = action.payload;
    }
  }
});

export const { setAccounts, setCurrentAccount, setCurrentTenantName, setFeatures } = AccountInfoSlice.actions;
export default AccountInfoSlice.reducer;
