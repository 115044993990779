import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

export const SwitchTenantButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.primary, // Use the theme's primary text color
  padding: 0, // Remove padding
  minWidth: 0, // Remove minimum width
  font: 'inherit',
  textTransform: 'inherit', // Don't transform text
  '&:hover': {
    backgroundColor: 'transparent' // Remove hover effect
  },
  '&:disabled': {
    color: theme.palette.text.primary,
    fontWeight: '600',
    backgroundColor: '#D9D9D9',
    cursor: 'default',
    textDecoration: 'none'
  }
}));
