enum FeatureFlags {
  ProfileModule = 'profile',
  ClientAdmin = 'clientAdmin',
  UserAdmin = 'organizationAdmin',
  Integrations = 'integrations',
  SegmentationAdminModule = 'segmentationAdmin',
  DataTaggingModule = 'dataTagging',
  DataTaggingAdministration = 'dataTaggingAdministration',
  DataTaggingAssignments = 'dataTaggingAssignments',
  DataTaggingMetrics = 'dataTaggingMetrics',
  DataTaggingDashboard = 'dataTaggingDashboard',
  Ticketmaster = 'ticketmaster',
  Dyehard = 'dyehard',
  ReportingModule = 'reporting',
  TenantIntegrationRegenerateDags = 'tenantIntegrationRegenerateDags',
  AzureSqlRoleManagement = 'azureSqlRoleManagement',
  SingleSignOn = 'singleSignOn',
  Segmentation = 'segmentation',
  TenantSwitcher = 'tenantSwitcher',
  ContactUploader = 'contactUploader',
  TicketTagging = 'ticketTagging',
  DataUploader = 'dataUploader',
  TMApiDropdown = 'tmApiDropdown'
}

export default FeatureFlags;
