/**
 * @returns a constant tenant id header key name
 */
export const tenantIdHeaderKey = 'org_id';
/**
 * @returns a constant tenant id local storage key name
 */
export const tenantIdLocalStorageKey = 'central_intelligence_tenant_id';
export const currentTenantIdLocalStorageKey = 'central_intelligence_current_tenant_id';

/**
 *
 * @param isCompanyTenant boolean indicating whether the user's organization is an Admin organization: Affinaquest in this case.
 * @param isCompanyTenantOnly optional parameter
 * @returns boolean. If `isCompanyTenantOnly` is false or undefined, returns true, otherwise checks if the current organization is a company org and returns true if `isCompanyTenantOnly` true
 */
export const canViewIfCompanyTenantOnly = (isCompanyTenant: boolean, isCompanyTenantOnly: boolean | undefined): boolean => {
  if (!isCompanyTenantOnly) return true;
  return isCompanyTenant && isCompanyTenantOnly;
};
