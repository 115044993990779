import { Redirect, Route } from 'react-router-dom';
import { canViewBasedOnRequiredChecks } from './visibility';

export const routeCreator = (mainPath: any, modulePath: string, accountInfo: any, ldFlags: any, isCompanyTenant: boolean) => {
  return (
    <Route
      key={mainPath.name}
      exact={mainPath.exact || false}
      path={`${modulePath}/${mainPath.url}`}
      component={
        canViewBasedOnRequiredChecks(accountInfo, ldFlags, { ...mainPath.permissions }, isCompanyTenant)
          ? mainPath.component
          : () => <Redirect to="/access-denied" />
      }
    />
  );
};

export const multiRouteCreator = (mainPaths: any, modulePath: string, accountInfo: any, ldFlags: any, isCompanyTenant: boolean) => {
  return mainPaths.map((mainPath: any) => {
    const childRoutes = mainPath.childRoutes?.map((path: any) => routeCreator(path, modulePath, accountInfo, ldFlags, isCompanyTenant)) || [];
    return [routeCreator(mainPath, modulePath, accountInfo, ldFlags, isCompanyTenant), ...childRoutes];
  });
};
