import { Paper, PaperProps } from '@mui/material';
import { styled } from '@mui/material/styles';

/**
 * A styled Paper component that can be re-used to display a top-level paper with default margins and padding
 * - All props are MUI Paper props
 * - more on Paper MUI component can be found [here](https://mui.com/components/paper/)
 */
const IndexPaperPageContent = styled(Paper)<PaperProps>(({ theme }) => ({
  margin: theme.spacing(1),
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  elevation: 6
}));

export default IndexPaperPageContent;
