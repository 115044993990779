import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { lazyMinLoadTime } from '../../../utils/codeSplitting';
import { Box } from '@mui/material';
import IndexPaperPageContent from '../../../styled/IndexPaperPageContent';
import { ModuleSubPath } from '../../../models/module';
import { Analytics, Cable, Campaign, Dashboard } from '@mui/icons-material';
import FeatureFlags from '../../../launchDarkly/featureFlags';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { SegmentationRoutes } from '../models/SegmentationRoutes';
import { multiRouteCreator } from '../../../utils/router';
import { useSelector } from 'react-redux';
import { selectCurrentAccount, selectIsCompanyTenant } from '../../../auth/AccountInfoSlice';
import Features from '../../../rbac/features';

const segmentationIFrameComponentPath = () => lazyMinLoadTime(() => import('./SegmentationIFrame'));
export const mainPaths: ModuleSubPath[] = [
  {
    url: `iFrame/:pageName`,
    name: 'Dashboard',
    param: 'dashboard',
    icon: <Dashboard />,
    component: segmentationIFrameComponentPath(),
    permissions: {
      // roles: [Roles.GlobalAdmin, Roles.Administrator, Roles.Segmentation],
      features: [Features.Segmentation],
      ldFlag: FeatureFlags.Segmentation
    },
    exact: true
  },
  {
    url: `iFrame/:pageName`,
    name: 'Campaign Manager',
    param: 'campaignManager',
    icon: <Campaign />,
    component: segmentationIFrameComponentPath(),
    permissions: {
      // roles: [Roles.GlobalAdmin, Roles.Administrator, Roles.Segmentation],
      features: [Features.Segmentation],
      ldFlag: FeatureFlags.Segmentation
    },
    exact: true
  },
  {
    url: `iFrame/:pageName`,
    name: 'Dataset Manager',
    param: 'datasetManager',
    icon: <Analytics />,
    component: segmentationIFrameComponentPath(),
    permissions: {
      // roles: [Roles.GlobalAdmin, Roles.Administrator, Roles.Segmentation],
      features: [Features.Segmentation],
      ldFlag: FeatureFlags.Segmentation
    },
    exact: true
  },
  {
    url: `iFrame/:pageName`,
    name: 'Connection Manager',
    param: 'connectionmanager',
    icon: <Cable />,
    component: segmentationIFrameComponentPath(),
    permissions: {
      // roles: [Roles.GlobalAdmin, Roles.Administrator],
      features: [Features.Segmentation],
      ldFlag: FeatureFlags.Segmentation
    },
    exact: true
  }
];

const SegmentationIndex: React.FunctionComponent = () => {
  const { path: modulePath } = useRouteMatch();
  const accountInfo = useSelector(selectCurrentAccount);
  const isCompanyTenant = useSelector(selectIsCompanyTenant);
  const ldFlags = useFlags();

  return (
    <Box id="segmentation-main" sx={{ height: '100%' }}>
      <IndexPaperPageContent id="main-container" sx={{ padding: 2 }}>
        <Switch>
          <Route exact path={modulePath}>
            <Redirect to={`${modulePath}/${SegmentationRoutes.Dashboard}`} />
          </Route>
          {multiRouteCreator(mainPaths, modulePath, accountInfo, ldFlags, isCompanyTenant)}
          {/* <Redirect to="/" /> */}
        </Switch>
      </IndexPaperPageContent>
    </Box>
  );
};

export default SegmentationIndex;
