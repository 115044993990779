import TicketmasterConfigurationModel from '../models/TicketmasterConfigurationModel';
import ReducerDefinition from '../../../interfaces/ReducerDefinition';
import { ClientAdminTenantTicketmasterConfigurationAction } from '../actions/clientAdminTenantTicketmasterConfiguration';
import { ReduxInitAction } from '../../../typings';
import { ClientAdminTenantTicketmasterConfigurationActionType } from '../actions/enums';

export interface ClientAdminTenantTicketmasterConfigurationState {
  isFetching: boolean;
  isLoading: boolean;
  config: TicketmasterConfigurationModel;
  validationErrors: any;
  fetchError: any;
  mutationError: any;
}

export const defaultClientAdminTenantTicketmasterConfigurationState: ClientAdminTenantTicketmasterConfigurationState = {
  isFetching: false,
  isLoading: false,
  config: { id: '', tenantIntegrationId: '', tmDsn: '', tmTeam: '', tmApi: '', active: true },
  validationErrors: {},
  fetchError: '',
  mutationError: ''
};

const clientAdminTenantTicketmasterConfigurationReducer: ReducerDefinition = {
  reducerName: 'tenant_ticketmaster_configuration',
  reducerFunction: (
    state: ClientAdminTenantTicketmasterConfigurationState = defaultClientAdminTenantTicketmasterConfigurationState,
    action: ClientAdminTenantTicketmasterConfigurationAction | ReduxInitAction
  ): ClientAdminTenantTicketmasterConfigurationState => {
    switch (action.type) {
      case ClientAdminTenantTicketmasterConfigurationActionType.IsFetching:
        return {
          ...state,
          isFetching: true
        } as ClientAdminTenantTicketmasterConfigurationState;
      case ClientAdminTenantTicketmasterConfigurationActionType.IsLoading:
        return {
          ...state,
          isLoading: true
        } as ClientAdminTenantTicketmasterConfigurationState;
      case ClientAdminTenantTicketmasterConfigurationActionType.FetchOneSuccess:
        return {
          ...state,
          config: action.payload,
          isFetching: false
        } as ClientAdminTenantTicketmasterConfigurationState;
      case ClientAdminTenantTicketmasterConfigurationActionType.FetchError:
        return {
          ...state,
          fetchError: action.payload,
          isFetching: false
        } as ClientAdminTenantTicketmasterConfigurationState;
      case ClientAdminTenantTicketmasterConfigurationActionType.MutationError:
        return {
          ...state,
          mutationError: action.payload,
          isLoading: false
        } as ClientAdminTenantTicketmasterConfigurationState;
      case ClientAdminTenantTicketmasterConfigurationActionType.OnChange:
        const { key, value } = action.payload;
        return {
          ...state,
          config: { ...state.config, [key]: value }
        };
      case ClientAdminTenantTicketmasterConfigurationActionType.SaveSuccess:
        return {
          ...state,
          config: action.payload,
          isLoading: false,
          validationErrors: {}
        } as ClientAdminTenantTicketmasterConfigurationState;
      case ClientAdminTenantTicketmasterConfigurationActionType.DeleteSuccess:
        return {
          ...state,
          config: { ...state.config, active: false } as TicketmasterConfigurationModel,
          isLoading: false,
          isFetching: false,
          validationErrors: {}
        } as ClientAdminTenantTicketmasterConfigurationState;
      case ClientAdminTenantTicketmasterConfigurationActionType.SetValidationErrors:
        return {
          ...state,
          validationErrors: action.payload,
          isLoading: false
        } as ClientAdminTenantTicketmasterConfigurationState;
      case ClientAdminTenantTicketmasterConfigurationActionType.ResetState:
        return {
          ...defaultClientAdminTenantTicketmasterConfigurationState
        } as ClientAdminTenantTicketmasterConfigurationState;
      default:
        return state;
    }
  }
};

export default clientAdminTenantTicketmasterConfigurationReducer;
