import 'react-toastify/dist/ReactToastify.css';
import 'ag-grid-community';
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import Paperbase from './components/Paperbase';
import AccountConfirmation from './pages/AccountConfirmation';
import React from 'react';
import Login from './components/auth/Login';
import useQuery from './hooks/useQuery';
import { useConfirmUserInvitationQuery } from './auth/UserApiSlice';
import AccessDenied from './pages/AccessDenied';
import { LoadingModal } from './components/modals/LoadingModal';

function App() {
  const { instance } = useMsal();
  const query = useQuery();
  const [confirmationId] = React.useState(query.get('confirmationId'));
  const [isConfirmedUser] = React.useState(confirmationId == null);
  const [isAccountConfirmationLoading, setIsAccountConfirmationLoading] = React.useState(false);
  const {
    data: user,
    isSuccess,
    isLoading
  } = useConfirmUserInvitationQuery({ confirmationId }, { skip: isAccountConfirmationLoading || confirmationId == null });

  const routeToLogin = isConfirmedUser || (isSuccess && user?.hasSSO);
  const routeToAccountConfirmation = isSuccess && user;
  const routeToLoading = isLoading || isAccountConfirmationLoading;
  const loadingMessage = isAccountConfirmationLoading ? 'Confirming Invitation!' : 'Loading...';

  return (
    <MsalProvider instance={instance}>
      <UnauthenticatedTemplate>
        {routeToLogin ? (
          <Login />
        ) : routeToAccountConfirmation ? (
          <AccountConfirmation
            email={user.email}
            firstName={user.firstName}
            lastName={user.lastName}
            userExists={user.userExists}
            confirmationId={confirmationId ?? ''}
            hasSSO={false}
            setIsLoading={setIsAccountConfirmationLoading}
          />
        ) : routeToLoading ? (
          <LoadingModal className="app-loader" isLoading={routeToLoading} message={loadingMessage} />
        ) : (
          <AccessDenied />
        )}
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <Paperbase />
      </AuthenticatedTemplate>
    </MsalProvider>
  );
}

export default App;
