import theme from './theme';
import { KeyStringAnyValue } from './typings';

type GlobalStyle = {
  className: string;
  selector: string;
  style: KeyStringAnyValue;
  sxStyle: KeyStringAnyValue;
};

/**
 * Defines the available global styling properties
 *
 * Each style key `has` to be implemented by the `globalStyles` object.
 *
 * Each style key is then available as part of the dot notation via intellisense
 */
type globalStyleKey = 'spacingSmall' | 'fullWidth';

/**
 * Uses mapped typing functionality to iterate through "keys" of  {@link globalStyleKey} type and create a `GlobalStyles` type
 */
type GlobalStyles = {
  [key in globalStyleKey]: GlobalStyle;
};

/**
 * globalStyles object that can be used to assign a className to a component(`It is recommended to assign the className to NON-MUI components as these are global classes and any MUI component with same styles will override the global class style)`
 *
 * To use the same styles from the global style/class on a MUI component, please use or extend the `sxStyle` property on the `sx` prop of the MUI component to assign the necessary styles to it without them being overriden
 * EX:
 * - `<Button sx={globalStyles.spacingSmall.xsStyle}>Test</Button>`
 *
 * - `<Button sx={{color: 'primary.main', ...globalStyles.spacingSmall.xsStyle}}>Test</Button>`
 *
 * @see sx Prop {@link https://mui.com/system/the-sx-prop/} and its theme aware properties
 */
const globalStyles: GlobalStyles = {
  spacingSmall: {
    className: 'ep-spacing-small',
    selector: '.ep-spacing-small',
    style: {
      margin: theme.spacing(1)
    },
    sxStyle: {
      margin: 1
    }
  },
  fullWidth: {
    className: 'ep-full-width',
    selector: '.ep-full-width',
    style: {
      width: '100%'
    },
    sxStyle: {
      width: '100%'
    }
  }
};

/**
 * globalClasses object that populates the MUI GlobalStyles component at the top of the app.
 *
 * `It is recommended to use these global classes on non-MUI components. As these are global classes, any MUI component with similar styles will override the global class style`
 *
 * To use the same global class styles on MUI components please @see {@link globalStyles}
 */
//build out the classes object via the keys from the globalStyles above to populate the MUI GlobalStyles component with class names and their styles
export const globalClasses = Object.keys(globalStyles).reduce((prev, key) => {
  const styleKey = key as globalStyleKey;
  return { ...prev, [`${globalStyles[styleKey].selector}`]: globalStyles[styleKey].style };
}, {});

export default globalStyles;
