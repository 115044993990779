import { api } from '../shared/api';

export const IntegrationMetadataSessionApiSlice = api.enhanceEndpoints({ addTagTypes: ['IntegrationMetadataSession'] }).injectEndpoints({
  endpoints: builder => ({
    integrationMetadataLogoutSession: builder.mutation<void, void>({
      query: () => ({
        url: `${process.env.REACT_APP_INTEGRATION_METADATA_BASE_URL}/api/v1/session/logout`,
        method: 'POST'
      })
    })
  })
});

export const TenantMetadataSessionApiSlice = api.enhanceEndpoints({ addTagTypes: ['TenantMetadataSession'] }).injectEndpoints({
  endpoints: builder => ({
    tenantMetadataLogoutSession: builder.mutation<void, void>({
      query: () => ({
        url: `${process.env.REACT_APP_TENANT_METADATA_BASE_URL}/api/v1/session/logout`,
        method: 'POST'
      })
    })
  })
});

export const UsersSessionApiSlice = api.enhanceEndpoints({ addTagTypes: ['UsersSession'] }).injectEndpoints({
  endpoints: builder => ({
    usersLogoutSession: builder.mutation<void, void>({
      query: () => ({
        url: `${process.env.REACT_APP_USERS_SERVICE_BASE_URL}/api/v1/session/logout`,
        method: 'POST'
      })
    })
  })
});

export const { useIntegrationMetadataLogoutSessionMutation } = IntegrationMetadataSessionApiSlice;
export const { useTenantMetadataLogoutSessionMutation } = TenantMetadataSessionApiSlice;
export const { useUsersLogoutSessionMutation } = UsersSessionApiSlice;
