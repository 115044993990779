import Box from '@mui/material/Box';
import AqLogoWhite from '../assets/components/AqLogoWhite';
import Typography from '@mui/material/Typography';
import CenteredBox from '../styled/CenteredBox';

const AccessDenied: React.FunctionComponent = () => {
  return (
    <Box
      sx={{
        bgcolor: '#fff',
        height: '90%',
        margin: '75px',
        borderRadius: '5px'
      }}
    >
      <CenteredBox
        sx={{
          top: '55%'
        }}
      >
        <AqLogoWhite />
      </CenteredBox>
      <CenteredBox
        sx={{
          top: '50%'
        }}
      >
        <Typography variant="h4" fontWeight="bold" align="center">
          Whoops!
        </Typography>
        <Typography variant="h5" fontWeight="bold">
          Looks like you don't have access to this page.
        </Typography>
      </CenteredBox>
      <CenteredBox
        sx={{
          top: '88%'
        }}
      >
        <Typography variant="body2">Please contact your organization's administrator to request access.</Typography>
      </CenteredBox>
    </Box>
  );
};

export default AccessDenied;
