import SsbModule from '../../models/module';
import HomeIcon from '@mui/icons-material/Home';
import { lazyMinLoadTime } from '../../utils/codeSplitting';

//Define a new SsbModule, this module requires a path to be used in the router, a functional component, and a name
const modulePrefix = 'home_';
const homeMod = new SsbModule({
  name: 'Home',
  path: '/home',
  modulePrefix: modulePrefix,
  component: lazyMinLoadTime(() => import('./components')),
  icon: <HomeIcon />
});

export default homeMod;
