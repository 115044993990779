import SalesforceConfigurationModel from '../models/SalesforceConfigurationModel';
import ReducerDefinition from '../../../interfaces/ReducerDefinition';
import { ClientAdminTenantSalesforceConfigurationAction } from '../actions/clientAdminTenantSalesforceConfiguration';
import { ReduxInitAction } from '../../../typings';
import { ClientAdminTenantSalesforceConfigurationActionType } from '../actions/enums';

export interface ClientAdminTenantSalesforceConfigurationState {
  isFetching: boolean;
  isLoading: boolean;
  config: SalesforceConfigurationModel;
  validationErrors: any;
  fetchError: any;
  mutationError: any;
}

const defaultSalesForceConfigurationModel: SalesforceConfigurationModel = {
  id: '',
  tenantIntegrationId: '',
  username: '',
  password: '',
  token: '',
  url: '',
  newRecordQueueThreshold: 1000,
  pushFailureThreshold: 10,
  active: true
};

export const defaultClientAdminTenantSalesforceConfigurationState: ClientAdminTenantSalesforceConfigurationState = {
  isFetching: false,
  isLoading: false,
  config: defaultSalesForceConfigurationModel,
  validationErrors: {},
  fetchError: '',
  mutationError: ''
};

const clientAdminTenantSalesforceConfigurationReducer: ReducerDefinition = {
  reducerName: 'tenant_salesforce_configuration',
  reducerFunction: (
    state: ClientAdminTenantSalesforceConfigurationState = defaultClientAdminTenantSalesforceConfigurationState,
    action: ClientAdminTenantSalesforceConfigurationAction | ReduxInitAction
  ): ClientAdminTenantSalesforceConfigurationState => {
    switch (action.type) {
      case ClientAdminTenantSalesforceConfigurationActionType.IsFetching:
        return {
          ...state,
          isFetching: true
        } as ClientAdminTenantSalesforceConfigurationState;
      case ClientAdminTenantSalesforceConfigurationActionType.IsLoading:
        return {
          ...state,
          isLoading: true
        } as ClientAdminTenantSalesforceConfigurationState;
      case ClientAdminTenantSalesforceConfigurationActionType.FetchOneSuccess:
        return {
          ...state,
          config: action.payload,
          isFetching: false
        } as ClientAdminTenantSalesforceConfigurationState;
      case ClientAdminTenantSalesforceConfigurationActionType.FetchError:
        return {
          ...state,
          fetchError: action.payload,
          isFetching: false
        } as ClientAdminTenantSalesforceConfigurationState;
      case ClientAdminTenantSalesforceConfigurationActionType.MutationError:
        return {
          ...state,
          mutationError: action.payload,
          isLoading: false
        } as ClientAdminTenantSalesforceConfigurationState;
      case ClientAdminTenantSalesforceConfigurationActionType.ConfigOnChange:
        const { key, value } = action.payload as { key: keyof SalesforceConfigurationModel; value: any };
        return {
          ...state,
          config: { ...state.config, [key]: value }
        } as ClientAdminTenantSalesforceConfigurationState;
      case ClientAdminTenantSalesforceConfigurationActionType.SaveSuccess:
        return {
          ...state,
          config: action.payload,
          isLoading: false,
          validationErrors: {}
        } as ClientAdminTenantSalesforceConfigurationState;
      case ClientAdminTenantSalesforceConfigurationActionType.DeleteSuccess:
        return {
          ...state,
          isLoading: false,
          isFetching: false,
          config: { ...state.config, active: false } as SalesforceConfigurationModel,
          validationErrors: {}
        } as ClientAdminTenantSalesforceConfigurationState;
      case ClientAdminTenantSalesforceConfigurationActionType.SetValidationErrors:
        return {
          ...state,
          validationErrors: action.payload,
          isLoading: false
        } as ClientAdminTenantSalesforceConfigurationState;
      case ClientAdminTenantSalesforceConfigurationActionType.ResetState:
        return {
          ...defaultClientAdminTenantSalesforceConfigurationState
        } as ClientAdminTenantSalesforceConfigurationState;
      default:
        return state;
    }
  }
};

export default clientAdminTenantSalesforceConfigurationReducer;
