export enum TicketTaggingRoutesEnum {
  BASE_ROUTE = '/ticket-tagging/',
  SEASON_SUMMARY_DASHBOARD = 'season-summary-dashboard',
  SEASON_CONFIGURATION = 'season-summary-dashboard/season-configuration',
  EVENT = 'season-summary-dashboard/season/:dimSeasonHeaderId'
}

export const buildEventRoute = (dimSeasonHeaderId: string): string => {
  const eventRouteWithId = TicketTaggingRoutesEnum.EVENT.replace(':dimSeasonHeaderId', dimSeasonHeaderId);
  return buildFullTicketTaggingRoute(eventRouteWithId);
};

export const buildFullTicketTaggingRoute = (route: string): string => {
  return TicketTaggingRoutesEnum.BASE_ROUTE + route;
};
