import React from 'react';
import { CombinedReducerDefinition } from '../typings';
import Roles from '../rbac/roles';
import Features from '../rbac/features';

export interface AccessPermissions {
  roles?: Array<Roles>;
  features?: Array<Features>;
  ldFlag?: string;
  isCompanyOrgOnly?: boolean;
  eitherRolesOrFeatures?: boolean;
}

export interface ModuleSubPath {
  name: string;
  url: string;
  key?: any;
  permissions?: AccessPermissions;
  param?: string;
  subNav?: ModuleSubPath[];
  folder?: string;
  icon?: React.ReactNode;
  component: React.FunctionComponent;
  exact?: boolean;
  childRoutes?: ModuleSubPath[];
  hideFromMenu?: boolean;
}

export interface ModuleProps {
  name: string;
  path: string;
  modulePrefix: string;
  component: React.FunctionComponent;
  icon: React.ReactNode;
  reducers?: CombinedReducerDefinition;
  permissions?: AccessPermissions;
  mainPaths?: ModuleSubPath[];
  dynamicallyGeneratedMenus?: Module[];
}

export default class Module {
  name: string;
  path: string;
  modulePrefix: string;
  component: React.FunctionComponent;
  icon?: React.ReactNode;
  reducers?: CombinedReducerDefinition;
  permissions?: AccessPermissions;
  mainPaths?: ModuleSubPath[];
  dynamicallyGeneratedMenus?: Module[];

  constructor(props: ModuleProps) {
    this.name = props.name;
    this.path = props.path;
    this.modulePrefix = props.modulePrefix;
    this.component = props.component;
    this.icon = props.icon || undefined;
    this.permissions = props.permissions;

    if (props.reducers) {
      this.reducers = props.reducers;
    }
    if (props.permissions?.roles && this.permissions) {
      this.permissions.roles = props.permissions.roles;
    }
    if (props.mainPaths) {
      this.mainPaths = props.mainPaths;
    }
    if (props.dynamicallyGeneratedMenus) {
      this.dynamicallyGeneratedMenus = props.dynamicallyGeneratedMenus;
    }
  }
}
