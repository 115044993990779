import { canViewBasedOnFeatureFlag } from '../launchDarkly/utils';
import FeatureFlags from '../launchDarkly/featureFlags';
import { Divider, ListItemIcon, MenuItem } from '@mui/material';
import { SwitchAccount } from '@mui/icons-material';
import { SwitchTenantButton } from '../styled/SwitchTenantButton';
import React from 'react';
import { LDFlagSet } from 'launchdarkly-js-client-sdk';

interface TenantSwitcherMenuItemProps {
  isMultiTenant: boolean;
  isGlobalAdmin: boolean;
  ldFlags: LDFlagSet;
  onClick?: () => void;
}

export const TenantSwitcherMenuItem = ({ isMultiTenant, isGlobalAdmin, ldFlags, onClick }: TenantSwitcherMenuItemProps) => {
  if (!(isMultiTenant || isGlobalAdmin) || !canViewBasedOnFeatureFlag(FeatureFlags.TenantSwitcher, ldFlags)) {
    return null;
  }

  return (
    <>
      <MenuItem onClick={onClick}>
        <ListItemIcon>
          <SwitchAccount color="action" />
        </ListItemIcon>
        <SwitchTenantButton>Switch Tenant</SwitchTenantButton>
      </MenuItem>
      <Divider />
    </>
  );
};
