import { AuthUserModel } from '../models/UserModels';
import { api } from '../shared/api';

type UserInvitationConfirmationPayload = {
  confirmationId: string | null;
};

export type UserConfirmationPayload = {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  confirmationId: string;
};

export type UserInvitationConfirmationResponse = {
  email: string;
  firstName: string;
  lastName: string;
  userExists: boolean;
  confirmationId: string;
  hasSSO: boolean;
};

const UserApiSlice = api.enhanceEndpoints({ addTagTypes: ['User', 'TenantId'] }).injectEndpoints({
  endpoints: builder => ({
    authorizeUser: builder.query<AuthUserModel[], string>({
      query: (userId: string) => `${process.env.REACT_APP_USERS_SERVICE_BASE_URL}/api/access/v2/${userId}/authorize`,
      providesTags: () => ['User']
    }),
    confirmUserInvitation: builder.query<UserInvitationConfirmationResponse, UserInvitationConfirmationPayload>({
      query: payload => ({
        url: `${process.env.REACT_APP_USERS_SERVICE_BASE_URL}/api/access/invite/validate`,
        method: 'POST',
        body: payload,
        meta: { ignoreTokenAcquisitionFailure: true }
      }),
      providesTags: () => ['User']
    }),
    confirmUser: builder.mutation<string, UserConfirmationPayload>({
      query: payload => ({
        url: `${process.env.REACT_APP_USERS_SERVICE_BASE_URL}/api/access/confirm`,
        method: 'POST',
        body: payload,
        meta: { ignoreTokenAcquisitionFailure: true }
      }),
      invalidatesTags: ['User']
    })
  })
});

export const { useAuthorizeUserQuery, useConfirmUserInvitationQuery, useConfirmUserMutation } = UserApiSlice;
