import React from 'react';
import ReactDOM from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import GlobalStyles from '@mui/material/GlobalStyles';
import { globalClasses } from './globalStyles';
import App from './App';
import theme from './theme';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import '@fontsource/dm-sans';
import { Provider } from 'react-redux';
import configureAppStore from './store/configureAppStore';
import { MsalProvider } from '@azure/msal-react';
import msalInstance, { configureMsalInstance } from './msal-instance';
import { Router } from 'react-router-dom';
import history from './history';

//setup launch darkly higher order component which can supply hooks such as: useFlags and useLDClient which use context API underneath and can be accessed across all components in the application
//wait until the connection to Launch Darkly is established and render the app, the initial load might be a bit slower but far outweighs the negatives if the app starts flashing during rendering when trying to use flags before connection is there

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LD_CLIENT_SIDE_ID as string
  });

  //setup global classes at the top of the application
  const appGlobalStyles = <GlobalStyles styles={globalClasses} />;

  const store = configureAppStore();

  configureMsalInstance().then(() => {
    ReactDOM.createRoot(document.querySelector('#root') as HTMLElement).render(
      <LDProvider>
        <MsalProvider instance={msalInstance}>
          <ThemeProvider theme={theme}>
            {/* Setup the styled injection at the top so MUI styles can be overriden */}
            <StyledEngineProvider injectFirst>
              {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
              <CssBaseline />
              <GlobalStyles
                styles={{
                  root: { height: '100%', overflow: 'hidden', width: '100%' }
                }}
              />
              {appGlobalStyles}
              <Provider store={store}>
                <Router history={history}>
                  <App />
                </Router>
              </Provider>
            </StyledEngineProvider>
          </ThemeProvider>
        </MsalProvider>
      </LDProvider>
    );
  });
})();
