import ReducerDefinition from '../../../interfaces/ReducerDefinition';
import { ClientAdminTenantIntegrationsActionType } from '../actions/enums';
import { ReduxInitAction } from '../../../typings';
import { ClientAdminTenantIntegrationsAction } from '../actions/clientAdminTenantIntegrations';
import { TenantIntegrationViewModel } from '../models/TenantIntegrationViewModel';
import { InputField } from '../../../models/InputField';
import { Schedule } from '../models/Schedule';

export interface ClientAdminTenantIntegrationsState {
  isFetching: boolean;
  isLoading: boolean;
  integrations: TenantIntegrationViewModel[];
  selectedTenantIntegration: TenantIntegrationViewModel;
  orgId: string;
  fetchError: any;
  mutationError: any;
  inputFields: InputField[];
  selectedSchedule: string;
  schedules: Schedule[];
}

export const defaultClientAdminTenantIntegrationsState: ClientAdminTenantIntegrationsState = {
  isFetching: false,
  isLoading: false,
  integrations: [] as TenantIntegrationViewModel[],
  selectedTenantIntegration: {} as TenantIntegrationViewModel,
  orgId: '',
  fetchError: '',
  mutationError: '',
  inputFields: [] as InputField[],
  selectedSchedule: '',
  schedules: [] as Schedule[]
};

const clientAdminTenantIntegrationsReducer: ReducerDefinition = {
  reducerName: 'tenant_integrations',
  reducerFunction: (
    state: ClientAdminTenantIntegrationsState = defaultClientAdminTenantIntegrationsState,
    action: ClientAdminTenantIntegrationsAction | ReduxInitAction
  ): ClientAdminTenantIntegrationsState => {
    switch (action.type) {
      case ClientAdminTenantIntegrationsActionType.FetchInit:
        return {
          ...state,
          isFetching: true
        } as ClientAdminTenantIntegrationsState;
      case ClientAdminTenantIntegrationsActionType.FetchSuccess:
        return {
          ...state,
          isFetching: false,
          integrations: action.payload
        } as ClientAdminTenantIntegrationsState;
      case ClientAdminTenantIntegrationsActionType.FetchError:
        return {
          ...state,
          isFetching: false,
          fetchError: action.payload
        } as ClientAdminTenantIntegrationsState;
      case ClientAdminTenantIntegrationsActionType.MutationError:
        return {
          ...state,
          isLoading: false,
          mutationError: action.payload
        } as ClientAdminTenantIntegrationsState;
      case ClientAdminTenantIntegrationsActionType.SaveInit:
        return {
          ...state,
          isLoading: true
        } as ClientAdminTenantIntegrationsState;
      case ClientAdminTenantIntegrationsActionType.MutationSuccess:
        return {
          ...state,
          isLoading: false,
          selectedSchedule: ''
        };
      case ClientAdminTenantIntegrationsActionType.AddSuccess:
        return {
          ...state,
          isLoading: false,
          integrations: [...state.integrations, action.payload]
        } as ClientAdminTenantIntegrationsState;
      case ClientAdminTenantIntegrationsActionType.DeleteSuccess:
        return {
          ...state,
          isLoading: false,
          integrations: action.payload,
          selectedTenantIntegration: { ...state.selectedTenantIntegration, tenantIntegrationActive: false }
        } as ClientAdminTenantIntegrationsState;
      case ClientAdminTenantIntegrationsActionType.ActivationSuccess:
        return {
          ...state,
          isLoading: false,
          integrations: action.payload,
          selectedTenantIntegration: { ...state.selectedTenantIntegration, tenantIntegrationActive: true }
        } as ClientAdminTenantIntegrationsState;
      case ClientAdminTenantIntegrationsActionType.OnSelect:
        return {
          ...state,
          selectedTenantIntegration: action.payload
        } as ClientAdminTenantIntegrationsState;
      case ClientAdminTenantIntegrationsActionType.OnChange:
        const { key, value } = action.payload;
        return {
          ...state,
          selectedTenantIntegration: { ...state.selectedTenantIntegration, [key]: value }
        } as ClientAdminTenantIntegrationsState;
      case ClientAdminTenantIntegrationsActionType.FetchAllInputFieldsSuccess:
        return {
          ...state,
          isFetching: false,
          inputFields: action.payload
        };
      case ClientAdminTenantIntegrationsActionType.updateSchedule:
        return {
          ...state,
          selectedSchedule: action.payload
        };
      case ClientAdminTenantIntegrationsActionType.FetchSchedulesSuccess:
        return {
          ...state,
          schedules: action.payload,
          isFetching: false
        };
      case ClientAdminTenantIntegrationsActionType.RegenerateDagsInit:
        return {
          ...state,
          isLoading: true
        };
      case ClientAdminTenantIntegrationsActionType.RegenerateDagsSuccess:
        return {
          ...state,
          isLoading: false
        };
      case ClientAdminTenantIntegrationsActionType.FetchMappingsSuccess:
        return {
          ...state,
          selectedTenantIntegration: { ...state.selectedTenantIntegration, savedMappings: action.payload },
          isFetching: false
        };
      default:
        return state;
    }
  }
};

export default clientAdminTenantIntegrationsReducer;
