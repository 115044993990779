import SFTPConnection from '../models/SFTPConnection';
import ReducerDefinition from '../../../interfaces/ReducerDefinition';
import { ReduxInitAction } from '../../../typings';
import { ClientAdminTenantSFTPConnectionActionType } from '../actions/enums';
import { ClientAdminTenantSFTPConnectionAction } from '../actions/clientAdminTenantSFTPConnection';

export interface ClientAdminTenantSFTPConnectionState {
  isFetching: boolean;
  isLoading: boolean;
  fetchError: any;
  mutationError: any;
  sftpConnections: SFTPConnection[];
  sftpConnection: SFTPConnection;
  validationErrors: {};
  formToggleState: keyof SFTPConnection;
}

export const defaultClientAdminTenantSFTPConnectionState: ClientAdminTenantSFTPConnectionState = {
  isFetching: false,
  isLoading: false,
  fetchError: '',
  mutationError: '',
  sftpConnections: [],
  sftpConnection: { tenantId: '', hostname: '', username: '', password: '', keyFilePath: '', active: true, id: '' } as SFTPConnection,
  validationErrors: {},
  formToggleState: 'password'
};

const clientAdminTenantSFTPConnectionReducer: ReducerDefinition = {
  reducerName: 'tenant_sftp_connection',
  reducerFunction: (
    state: ClientAdminTenantSFTPConnectionState = defaultClientAdminTenantSFTPConnectionState,
    action: ClientAdminTenantSFTPConnectionAction | ReduxInitAction
  ): ClientAdminTenantSFTPConnectionState => {
    switch (action.type) {
      case ClientAdminTenantSFTPConnectionActionType.IsFetching:
        return {
          ...state,
          isFetching: true
        } as ClientAdminTenantSFTPConnectionState;
      case ClientAdminTenantSFTPConnectionActionType.IsLoading:
        return {
          ...state,
          isLoading: true
        } as ClientAdminTenantSFTPConnectionState;
      case ClientAdminTenantSFTPConnectionActionType.FetchAllSuccess:
        return {
          ...state,
          isFetching: false,
          sftpConnections: action.payload
        } as ClientAdminTenantSFTPConnectionState;
      case ClientAdminTenantSFTPConnectionActionType.FetchOneSuccess:
        return {
          ...state,
          isFetching: false,
          sftpConnection: action.payload
        } as ClientAdminTenantSFTPConnectionState;
      case ClientAdminTenantSFTPConnectionActionType.FetchError:
        return {
          ...state,
          isFetching: false,
          fetchError: action.payload
        } as ClientAdminTenantSFTPConnectionState;
      case ClientAdminTenantSFTPConnectionActionType.MutationError:
        return {
          ...state,
          isLoading: false,
          mutationError: action.payload
        } as ClientAdminTenantSFTPConnectionState;
      case ClientAdminTenantSFTPConnectionActionType.SaveSuccess:
        return {
          ...state,
          isLoading: false,
          sftpConnections: [...state.sftpConnections, action.payload],
          sftpConnection: action.payload,
          validationErrors: defaultClientAdminTenantSFTPConnectionState.validationErrors
        } as ClientAdminTenantSFTPConnectionState;
      case ClientAdminTenantSFTPConnectionActionType.ResetConnection:
        return {
          ...state,
          sftpConnection: defaultClientAdminTenantSFTPConnectionState.sftpConnection,
          validationErrors: defaultClientAdminTenantSFTPConnectionState.validationErrors
        } as ClientAdminTenantSFTPConnectionState;
      case ClientAdminTenantSFTPConnectionActionType.ResetState:
        return {
          ...state,
          sftpConnections: defaultClientAdminTenantSFTPConnectionState.sftpConnections,
          sftpConnection: defaultClientAdminTenantSFTPConnectionState.sftpConnection,
          validationErrors: defaultClientAdminTenantSFTPConnectionState.validationErrors
        } as ClientAdminTenantSFTPConnectionState;
      case ClientAdminTenantSFTPConnectionActionType.OnChange:
        const { key: sftpKey, value: sftpValue } = action.payload;
        return {
          ...state,
          sftpConnection: {
            ...state.sftpConnection,
            [sftpKey]: sftpValue
          }
        } as ClientAdminTenantSFTPConnectionState;
      case ClientAdminTenantSFTPConnectionActionType.SetValidationErrors:
        return {
          ...state,
          isLoading: false,
          validationErrors: action.payload
        } as ClientAdminTenantSFTPConnectionState;
      case ClientAdminTenantSFTPConnectionActionType.SetFormToggleState:
        //MUI toggle passes null if active button is clicked again and deselects button. This prevents that behavior.
        if (action.payload !== null) {
          return {
            ...state,
            formToggleState: action.payload
          };
        } else return { ...state };
      default:
        return state;
    }
  }
};

export default clientAdminTenantSFTPConnectionReducer;
