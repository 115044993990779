import { BrowserCacheLocation, Configuration } from '@azure/msal-browser';

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: `${process.env.REACT_APP_MSAL_CLIENTID}`, // This is the ONLY mandatory field that you need to supply.
    authority: `https://${process.env.REACT_APP_MSAL_TENANT}.b2clogin.com/${process.env.REACT_APP_MSAL_TENANT}.onmicrosoft.com/${process.env.REACT_APP_MSAL_POLICY}`, // Choose SUSI as your default authority.
    knownAuthorities: [`${process.env.REACT_APP_MSAL_TENANT}.b2clogin.com`], // Mark your B2C tenant's domain as trusted.
    redirectUri: window.location.origin, // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    postLogoutRedirectUri: window.location.origin, // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: false // If 'true', will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: BrowserCacheLocation.SessionStorage, // Configures cache location. 'sessionStorage' is more secure, but 'localStorage' gives you SSO between tabs.
    storeAuthStateInCookie: true // Set this to 'true' if you are having issues on IE11 or Edge
  },
  system: {
    allowNativeBroker: false // Disables WAM Broker
  }
};

interface ResourceConfig {
  endpoint: string;
  scopes: {
    default: string[];
  };
}

interface ProtectedResources {
  [key: string]: ResourceConfig;
}

export const protectedResources: ProtectedResources = {
  ciUsersService: {
    endpoint: process.env.REACT_APP_USERS_SERVICE_BASE_URL as string,
    scopes: {
      default: [process.env.REACT_APP_USERS_SERVICE_SCOPE as string]
    }
  },
  ciTenantMetadataService: {
    endpoint: process.env.REACT_APP_TENANT_METADATA_BASE_URL as string,
    scopes: {
      default: [process.env.REACT_APP_TENANT_METADATA_SCOPE as string]
    }
  },
  ciIntegrationsMetadataService: {
    endpoint: process.env.REACT_APP_INTEGRATION_METADATA_BASE_URL as string,
    scopes: {
      default: [process.env.REACT_APP_INTEGRATION_METADATA_SCOPE as string]
    }
  }
};

export const findDefaultScopeByUrl = (url: string): string[] | null => {
  for (let key in protectedResources) {
    if (protectedResources.hasOwnProperty(key)) {
      const service = protectedResources[key];
      if (url.includes(service.endpoint)) {
        return service.scopes.default;
      }
    }
  }
  return null;
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
// export const loginRequest: PopupRequest = {
//   scopes: ['User.Read']
// }

// Add here the endpoints for MS Graph API services you would like to use.
// export const graphConfig = {
//   graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me'
// }
