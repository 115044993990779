import FeatureModel from '../modules/user-admin/models/FeatureModel';
import Module from '../models/module';
import { moduleBuilder } from '../modules/reporting/components/moduleBuilder';
import FeatureFlags from '../launchDarkly/featureFlags';

enum ReportSubCategory {
  grm = 'Golden Record Management',
  tools = 'Tools',
  reports = 'Reports',
  ops = 'Ops Management',
  monitoring = 'Monitoring',
  customReports = 'Custom Reports'
}

const arrayOfReportSubCategories: string[] = Object.values(ReportSubCategory);

export const generateDynamicMenusFromModules = (features: FeatureModel[], modules: Module[]): Module[] => {
  const featureGroups = Object.values(ReportSubCategory).reduce((acc: any, category) => {
    acc[category] = features.filter((feature: FeatureModel) => feature.menuCategory?.toLowerCase() === category.toLowerCase());
    return acc;
  }, {});

  const dynamicMenus = Object.values(ReportSubCategory).reduce((acc: any, category) => {
    acc[category] = moduleBuilder([], FeatureFlags.ReportingModule, featureGroups[category]);
    return acc;
  }, {});

  return modules
    .map((module: Module): Module => {
      // if module is not a report module, return it
      if (!arrayOfReportSubCategories.includes(module.name)) {
        return module;
      }
      const clonedModule = { ...module };
      // if module is a report module, add the dynamically generated menus to it
      // Golden Record Management may not have dynamically generated menus (reports),
      // but it should be included in the list of modules because it has ContactUploader
      // thus allowing for mainPaths.length to pass the filter below
      if ((dynamicMenus.hasOwnProperty(module.name) && dynamicMenus[module.name].length) || module.mainPaths?.length) {
        clonedModule.dynamicallyGeneratedMenus = dynamicMenus[module.name];
        return clonedModule;
      }
      // if module is a report module but has no dynamically generated menus (reports), return an empty object
      return {} as Module;
    })
    .filter(mod => Object.keys(mod).length > 0); // filter out empty objects
};
