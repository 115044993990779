import ReducerDefinition from '../../../interfaces/ReducerDefinition';
import { ClientAdminIntegrationTenantsActionType } from '../actions/enums';
import { ReduxInitAction } from '../../../typings';
import IntegrationTenantModel from '../models/IntegrationTenantModel';
import { ClientAdminIntegrationTenantsAction } from '../actions/clientAdminIntegrationTenants';

export interface ClientAdminIntegrationTenantsState {
  isFetching: boolean;
  integrationTenants: IntegrationTenantModel[];
  orgId: string;
  fetchError: any;
}

export const defaultClientAdminIntegrationTenantsState: ClientAdminIntegrationTenantsState = {
  isFetching: false,
  integrationTenants: [],
  orgId: '',
  fetchError: ''
};

const clientAdminIntegrationTenantsReducer: ReducerDefinition = {
  reducerName: 'integration_tenants',
  reducerFunction: (
    state: ClientAdminIntegrationTenantsState = defaultClientAdminIntegrationTenantsState,
    action: ClientAdminIntegrationTenantsAction | ReduxInitAction
  ): ClientAdminIntegrationTenantsState => {
    switch (action.type) {
      case ClientAdminIntegrationTenantsActionType.FetchInit:
        return {
          ...state,
          isFetching: true
        } as ClientAdminIntegrationTenantsState;
      case ClientAdminIntegrationTenantsActionType.FetchSuccess:
        return {
          ...state,
          isFetching: false,
          integrationTenants: action.payload
        } as ClientAdminIntegrationTenantsState;
      case ClientAdminIntegrationTenantsActionType.FetchError:
        return {
          ...state,
          isFetching: false,
          fetchError: action.payload
        } as ClientAdminIntegrationTenantsState;
      default:
        return state;
    }
  }
};

export default clientAdminIntegrationTenantsReducer;
