import ReducerDefinition from '../../../interfaces/ReducerDefinition';
import { ReduxInitAction } from '../../../typings';
import TenantModel from '../models/TenantModel';
import { ClientAdminTenantActionType } from '../actions/enums';
import { ClientAdminTenantAction } from '../actions/clientAdminTenant';

export interface ClientAdminTenantState {
  tenantDetails: TenantModel;
}

const defaultTenantDetails: TenantModel = { id: '', orgId: '', name: '', key: '', url: '', active: true };

export const defaultClientAdminTenantState: ClientAdminTenantState = {
  tenantDetails: defaultTenantDetails
};

const clientAdminTenantReducer: ReducerDefinition = {
  reducerName: 'tenant',
  reducerFunction: (
    state: ClientAdminTenantState = defaultClientAdminTenantState,
    action: ClientAdminTenantAction | ReduxInitAction
  ): ClientAdminTenantState => {
    switch (action.type) {
      case ClientAdminTenantActionType.ResetState:
        return {
          ...defaultClientAdminTenantState
        } as ClientAdminTenantState;

      case ClientAdminTenantActionType.FetchSuccess:
        return {
          ...state,
          tenantDetails: action.payload
        } as ClientAdminTenantState;
      default:
        return state;
    }
  }
};

export default clientAdminTenantReducer;
