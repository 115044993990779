import { createTheme } from '@mui/material/styles';

// A custom theme for this app
export let theme = createTheme({
  palette: {
    primary: {
      light: '#A399FF',
      main: '#5135CE',
      dark: '#0B004C',
      contrastText: '#fff'
    },
    secondary: {
      light: '#00DB6B',
      main: '#00B250',
      dark: '#007F36',
      contrastText: '#fff'
    },
    info: {
      light: '#0092FF',
      main: '#006BCC',
      dark: '#003666',
      contrastText: '#fff'
    },
    warning: {
      light: '#FF9E00',
      main: '#F18701',
      dark: '#FF5400',
      contrastText: '#000000'
    },
    error: {
      main: '#B00020',
      contrastText: '#fff'
    },
    background: {
      default: '#fff'
    }
  },
  typography: {
    fontFamily: ['DM Sans', 'sans-serif'].join(','),
    fontWeightRegular: '400',
    fontWeightMedium: '500',
    fontWeightBold: '700',
    h5: {
      fontSize: '1.5625rem',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '133.4%' /* 2.08438rem */
    },
    body1: {
      fontSize: '1.0625rem',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '150%' /* 1.59375rem */,
      letterSpacing: '0.03125rem'
    },
    body2: {
      fontSize: '0.875rem',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '1.25rem' /* 142.857% */,
      letterSpacing: '0.01563rem'
    },
    subtitle1: {
      fontSize: '1.0625rem',
      fontStyle: 'normal',
      fontHeight: 400,
      lineWeight: '175%' /* 1.85938rem */,
      letterSpacing: '0.00938rem'
    },
    caption: {
      fontSize: '0.875rem',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '1.25rem' /* 142.857% */,
      letterSpacing: '0.01563rem',
      color: 'rgba(0, 0, 0, 0.60)'
    }
  },
  shape: {
    borderRadius: 8
  },
  mixins: {
    toolbar: {
      minHeight: 48
    }
  }
});

theme = {
  ...theme,
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: theme.palette.primary.dark
        }
      }
    },
    //setup the dialog modal title so we can display a close button on the right hand side of the dialog. We create a space-between on the DialogTitle component and align to center to make sure the title and close button are on the other side of each other
    MuiDialog: {
      styleOverrides: {
        root: {
          '& .MuiDialogTitle-root': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '0.25rem',
          label: {
            textTransform: 'none'
          }
        },
        contained: {
          // boxShadow: 'none',
          '&:active': {
            boxShadow: 'none'
          }
        }
      }
    },
    // MuiTabs: {
    //   styleOverrides: {
    //     root: {
    //       marginLeft: theme.spacing(1)
    //     },
    //     indicator: {
    //       height: 3,
    //       borderTopLeftRadius: 3,
    //       borderTopRightRadius: 3,
    //       backgroundColor: theme.palette.common.white
    //     }
    //   }
    // },
    // MuiTab: {
    //   styleOverrides: {
    //     root: {
    //       textTransform: 'none',
    //       margin: '0 16px',
    //       minWidth: 0,
    //       padding: 0,
    //       [theme.breakpoints.up('md')]: {
    //         padding: 0,
    //         minWidth: 0
    //       }
    //     }
    //   }
    // },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1)
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 4
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: 'grey.A200'
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontWeight: theme.typography.fontWeightMedium
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: 'inherit',
          marginRight: 0,
          '& svg': {
            fontSize: 23 //increased to 23 from 20 just so the icons are more present
          }
        }
      }
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 32,
          height: 32
        }
      }
    }
  }
};

export default theme;
