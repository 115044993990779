import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Box, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuProps, styled } from '@mui/material';
import { removeWhiteSpaces } from '../../utils/regex';
import clsx from 'clsx';
import { navClasses } from './Navigator';
import { ChevronRight } from '@mui/icons-material';
import Module, { ModuleSubPath } from '../../models/module';
import { canViewBasedOnRequiredChecks } from '../../utils/visibility';
import { AccountInfoType, selectIsCompanyTenant } from '../../auth/AccountInfoSlice';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { setIsDrawerOpenDesktop } from '../../actions';
import { ThunkDispatch } from 'redux-thunk';
import { Dispatch } from 'redux';

interface DynamicallyGeneratedMenuItemsProps {
  modules: Module[];
  accountInfo: AccountInfoType;
  pathName: string;
  isPinnedMenuIcon: boolean;
  handleDrawerToggleDesktop: typeof setIsDrawerOpenDesktop;
  onListItemClick: (module: Module, path?: ModuleSubPath) => void;
}

// this needs to accept an array of modules because of how it handles the anchor elements for the popups.
// This allows it to be self-contained as opposed to having to keep track of the anchor elements in a higher component
// which might get confusing.
export const DynamicallyGeneratedMenuItems: React.FunctionComponent<DynamicallyGeneratedMenuItemsProps> = ({
  modules,
  accountInfo,
  pathName,
  handleDrawerToggleDesktop,
  isPinnedMenuIcon,
  onListItemClick
}: DynamicallyGeneratedMenuItemsProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedFolder, setSelectedFolder] = useState<string>('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const ldFlags = useFlags();
  const isCompanyTenant = useSelector(selectIsCompanyTenant);

  const handleClick = (module: Module, index: number) => (event: any) => {
    if (module.name === selectedFolder) {
      // close the popover and deselect if the same folder is clicked
      handleFolderClose();
      return;
    }
    // open the popover and select the folder if a different folder is clicked
    setAnchorEl(event.currentTarget);
    setCurrentIndex(index);
    setSelectedFolder(module.name);
  };

  const handleClose = (module: any, path: any) => {
    setAnchorEl(null);
    setSelectedFolder('');
    onListItemClick(module, path);
    if (!isPinnedMenuIcon) handleDrawerToggleDesktop(false);
  };

  const handleFolderClose = () => {
    setAnchorEl(null);
    setSelectedFolder('');
  };

  const selected = (module: Module, moduleSubPath?: ModuleSubPath) => {
    if (moduleSubPath?.param?.length) {
      const insertedParam = moduleSubPath.url.split(':')[0] + moduleSubPath.param;
      return pathName.includes(`${module.path}/${insertedParam}`);
    } else if (moduleSubPath) {
      return pathName.includes(`${module.path}/${moduleSubPath.url}`);
    } else {
      return pathName.includes(`${module.path}`);
    }
  };

  const tempSelected = (module: Module) => {
    return selectedFolder === module?.name;
  };

  const addOverFlowEllipsis = (moduleName: string) => {
    return moduleName.length > 30 ? moduleName.substring(0, 30).concat('...') : moduleName;
  };

  const subMenuSelected = (module: Module) => {
    // reports and custom reports have duplicate subCategories, so we need to check for the subPath as well to prevent multiple subCategories from being selected
    const category = pathName.split('/')[2]?.toLowerCase();
    const moduleCategory = module.path.split('/')[2]?.toLowerCase();
    const subPath = pathName.split('/')[3]?.toLowerCase();
    const moduleSubPath = module.path.split('/')[3]?.toLowerCase();
    return subPath === moduleSubPath && category === moduleCategory;
  };

  const StyledMenu = styled((props: MenuProps) => <Menu {...props} />)(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 0,
      color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0'
      }
    }
  }));

  const buildPopoverMenuItems = (module: Module, index: number) => (
    <div key={`${module.name}`} id="menu-popover-item">
      <ListItemButton
        key={module.name}
        component="li"
        id={removeWhiteSpaces(module.name)}
        onClick={handleClick(module, index)}
        aria-controls={Boolean(anchorEl) && index === currentIndex ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={Boolean(anchorEl) && index === currentIndex ? 'true' : undefined}
        selected={subMenuSelected(module)}
        className={clsx(navClasses.nestedListItem, navClasses.item, subMenuSelected(module) ? navClasses.itemActiveItem : '')}
      >
        <ListItemIcon>{module?.icon}</ListItemIcon>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          className={clsx(tempSelected(module) ? navClasses.itemSubActiveItem : '')}
        >
          <ListItemText primary={addOverFlowEllipsis(module.name)} sx={{ paddingBottom: '0.25rem' }} />
          <ChevronRight id={`${removeWhiteSpaces(module.name)}-nav-menu-expand-less`} />
        </Box>
      </ListItemButton>
      <StyledMenu
        id={removeWhiteSpaces(module.name)}
        anchorEl={anchorEl}
        open={Boolean(anchorEl) && module.name === selectedFolder}
        onClose={handleFolderClose}
        MenuListProps={{
          'aria-labelledby': module.name
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        {module.mainPaths?.map((subPath: ModuleSubPath) => {
          if (canViewBasedOnRequiredChecks(accountInfo, ldFlags, { ...subPath.permissions }, isCompanyTenant)) {
            return (
              <MenuItem selected={selected(module, subPath)} key={subPath.key} onClick={() => handleClose(module, subPath)}>
                {subPath.name}
              </MenuItem>
            );
          }
          return null;
        })}
      </StyledMenu>
    </div>
  );

  const buildClickableMenuItem = (module: Module, moduleSubPath: ModuleSubPath, index: number) => (
    <div key={`${moduleSubPath.key}`} id="menu-clickable-item">
      <ListItemButton
        key={moduleSubPath.key}
        component="li"
        id={removeWhiteSpaces(moduleSubPath.name)}
        onClick={() => handleClose(module, moduleSubPath)}
        aria-controls={Boolean(anchorEl) && index === currentIndex ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={Boolean(anchorEl) && index === currentIndex ? 'true' : undefined}
        selected={selected(module, moduleSubPath)}
        className={clsx(navClasses.nestedListItem, navClasses.item, selected(module, moduleSubPath) ? navClasses.itemActiveItem : '')}
      >
        <ListItemIcon>{moduleSubPath?.icon}</ListItemIcon>
        <ListItemText primary={moduleSubPath.name || ''} />
      </ListItemButton>
    </div>
  );

  const buildMenuItems = (module: Module, index: number) => {
    // If module has a subCategory name build a popover menu
    if (module.name.length) {
      return buildPopoverMenuItems(module, index);
    }
    // If module has main paths and doesn't have a subCategory name, build a clickable menu item
    if (module.mainPaths) {
      return module.mainPaths.map((subPath: ModuleSubPath, subPathIndex: number) => {
        return buildClickableMenuItem(module, subPath, subPathIndex);
      });
    }

    return null;
  };

  return <>{modules.map((module: Module, index: number) => buildMenuItems(module, index))}</>;
};

const mapStateToProps = (state: any) => {
  return {
    isPinnedMenuIcon: state.navigator.isPinnedMenuIcon
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, never, any> | Dispatch<any>) => ({
  handleDrawerToggleDesktop: (isOpen: boolean) => dispatch(setIsDrawerOpenDesktop(isOpen))
});

export default connect(mapStateToProps, mapDispatchToProps)(DynamicallyGeneratedMenuItems);
