import { OpenIdModel } from '../models/OpenIdModel';
import ReducerDefinition from '../../../interfaces/ReducerDefinition';
import { ReduxInitAction } from '../../../typings';
import { SingleSignOnAction } from '../actions/singleSignOn';
import { SingleSignOnActionType } from '../actions/enums';
import { SamlModel } from '../models/SamlModel';
import { SingleSignOnType } from '../models/enums/SingleSignOnType';

export interface SingleSignOnState {
  isSaving: boolean;
  openId: OpenIdModel;
  saml: SamlModel;
  showModal: boolean;
  currentForm: SingleSignOnType.openId | SingleSignOnType.saml | undefined;
}

export const defaultSingleSignOnState = {
  isSaving: false,
  openId: {} as OpenIdModel,
  saml: {} as SamlModel,
  showModal: false,
  currentForm: undefined
};

const singleSignOnReducer: ReducerDefinition = {
  reducerName: 'SingleSignOn',
  reducerFunction: (state: SingleSignOnState = defaultSingleSignOnState, action: SingleSignOnAction | ReduxInitAction): SingleSignOnState => {
    switch (action.type) {
      case SingleSignOnActionType.ShowModal:
        return {
          ...state,
          showModal: true,
          currentForm: undefined
        };
      case SingleSignOnActionType.CloseModal:
        return {
          ...state,
          showModal: false
        };
      case SingleSignOnActionType.SetForm:
        return {
          ...state,
          currentForm: action.payload
        };
      case SingleSignOnActionType.SaveInit:
        return {
          ...state,
          isSaving: true
        };
      case SingleSignOnActionType.OpenIdOnChange:
        return {
          ...state,
          openId: { ...state.openId, [action.payload.key]: action.payload.value }
        };
      case SingleSignOnActionType.SamlOnChange:
        return {
          ...state,
          saml: { ...state.saml, [action.payload.key]: action.payload.value }
        };
      case SingleSignOnActionType.ResetState:
        return {
          ...state,
          isSaving: defaultSingleSignOnState.isSaving,
          openId: defaultSingleSignOnState.openId,
          saml: defaultSingleSignOnState.saml
        };
      case SingleSignOnActionType.MutationError:
        return {
          ...state,
          isSaving: false
        };
      default:
        return state;
    }
  }
};

export default singleSignOnReducer;
