import { AlertModel } from '../models/AlertModel';
import { AlertAction } from '../actions';
import { AlertActionType } from '../actions/enums';

export interface AlertState {
  alert: AlertModel;
}

export const defaultAlertState = {
  alert: {} as AlertModel
};

const alertReducer = (state: AlertState = defaultAlertState, action: AlertAction | { type: '@@INIT' }) => {
  switch (action.type) {
    case AlertActionType.SetAlert:
      return {
        ...state,
        alert: action.payload
      } as AlertState;
    case AlertActionType.ClearAlert:
      return {
        ...state,
        alert: {}
      } as AlertState;
    default:
      return state;
  }
};

export default alertReducer;
