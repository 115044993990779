import { canViewBasedOnFeatures, canViewBasedOnRoles } from '../rbac/utils';
import { canViewIfCompanyTenantOnly } from './org';
import { AccessPermissions } from '../models/module';
import { LDFlagSet } from 'launchdarkly-js-client-sdk';
import { canViewBasedOnFeatureFlag } from '../launchDarkly/utils';
import { AccountInfoType } from '../auth/AccountInfoSlice';

export const canViewBasedOnRequiredChecks = (
  accountInfo: AccountInfoType,
  ldFlags: LDFlagSet,
  permissions: AccessPermissions,
  isCompanyTenant: boolean
) => {
  if (permissions.eitherRolesOrFeatures) {
    return (
      (canViewBasedOnRoles(accountInfo.roles, permissions.roles) || canViewBasedOnFeatures(accountInfo.features, permissions.features)) &&
      canViewIfCompanyTenantOnly(isCompanyTenant, permissions.isCompanyOrgOnly) &&
      canViewBasedOnFeatureFlag(permissions.ldFlag, ldFlags)
    );
  }
  return (
    canViewBasedOnRoles(accountInfo.roles, permissions.roles) &&
    canViewBasedOnFeatures(accountInfo.features, permissions.features) &&
    canViewIfCompanyTenantOnly(isCompanyTenant, permissions.isCompanyOrgOnly) &&
    canViewBasedOnFeatureFlag(permissions.ldFlag, ldFlags)
  );
};
