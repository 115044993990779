export enum ClientAdminActionType {
  FetchInit = 'CLIENT_ADMIN_FETCH_INIT',
  FetchSuccess = 'CLIENT_ADMIN_FETCH_SUCCESS',
  FetchError = 'CLIENT_ADMIN_FETCH_ERROR',
  SaveSuccess = 'CLIENT_ADMIN_SAVE_SUCCESS'
}

export enum ClientAdminIntegrationsActionType {
  FetchInit = 'CLIENT_ADMIN_INTEGRATIONS_FETCH_INIT',
  FetchSuccess = 'CLIENT_ADMIN_INTEGRATIONS_FETCH_SUCCESS',
  FetchError = 'CLIENT_ADMIN_INTEGRATIONS_FETCH_ERROR',
  SaveSuccess = 'CLIENT_ADMIN_INTEGRATIONS_SAVE_SUCCESS'
}

export enum ClientAdminIntegrationEndpointsActionType {
  FetchInit = 'CLIENT_ADMIN_INTEGRATION_ENDPOINTS_FETCH_INIT',
  IsLoading = 'CLIENT_ADMIN_INTEGRATION_ENDPOINTS_IS_LOADING',
  FetchSuccess = 'CLIENT_ADMIN_INTEGRATION_ENDPOINTS_FETCH_SUCCESS',
  FetchError = 'CLIENT_ADMIN_INTEGRATION_ENDPOINTS_FETCH_ERROR',
  MutationError = 'CLIENT_ADMIN_INTEGRATION_ENDPOINTS_MUTATION_ERROR',
  SaveSuccess = 'CLIENT_ADMIN_INTEGRATION_ENDPOINTS_SAVE_SUCCESS',
  OnChange = 'CLIENT_ADMIN_INTEGRATION_ENDPOINTS_ON_CHANGE'
}

export enum ClientAdminIntegrationTenantsActionType {
  FetchInit = 'CLIENT_ADMIN_INTEGRATION_TENANTS_FETCH_INIT',
  FetchSuccess = 'CLIENT_ADMIN_INTEGRATION_TENANTS_FETCH_SUCCESS',
  FetchError = 'CLIENT_ADMIN_INTEGRATION_TENANTS_FETCH_ERROR',
  SaveSuccess = 'CLIENT_ADMIN_INTEGRATION_TENANTS_SAVE_SUCCESS'
}

export enum ClientAdminTenantIntegrationsActionType {
  FetchInit = 'CLIENT_ADMIN_TENANT_INTEGRATIONS_FETCH_INIT',
  SaveInit = 'CLIENT_ADMIN_TENANT_INTEGRATIONS_SAVE_INIT',
  MutationSuccess = 'CLIENT_ADMIN_TENANT_INTEGRATIONS_MUTATION_SUCCESS',
  FetchSuccess = 'CLIENT_ADMIN_TENANT_INTEGRATIONS_FETCH_SUCCESS',
  FetchSchedulesSuccess = 'CLIENT_ADMIN_TENANT_INTEGRATIONS_FETCH_SCHEDULES_SUCCESS',
  FetchError = 'CLIENT_ADMIN_TENANT_INTEGRATIONS_FETCH_ERROR',
  MutationError = 'CLIENT_ADMIN_TENANT_INTEGRATIONS_MUTATION_ERROR',
  AddSuccess = 'CLIENT_ADMIN_TENANT_INTEGRATIONS_ADD_SUCCESS',
  OnSelect = 'CLIENT_ADMIN_TENANT_INTEGRATIONS_ON_SELECT',
  OnChange = 'CLIENT_ADMIN_TENANT_INTEGRATIONS_ON_CHANGE',
  FetchAllInputFieldsSuccess = 'CLIENT_ADMIN_TENANT_INTEGRATIONS_FETCH_ALL_INPUT_FIELDS_SUCCESS',
  FetchMappingsSuccess = 'CLIENT_ADMIN_TENANT_INTEGRATIONS_FETCH_MAPPINGS_SUCCESS',
  updateSchedule = 'CLIENT_ADMIN_TENANT_INTEGRATIONS_UPDATE_SCHEDULE',
  DeleteSuccess = 'CLIENT_ADMIN_TENANT_INTEGRATIONS_DELETE_SUCCESS',
  ActivationSuccess = 'CLIENT_ADMIN_TENANT_INTEGRATIONS_ACTIVATION_SUCCESS',
  RegenerateDagsInit = 'CLIENT_ADMIN_TENANT_INTEGRATIONS_REGENERATE_DAGS_INIT',
  RegenerateDagsSuccess = 'CLIENT_ADMIN_TENANT_INTEGRATIONS_REGENERATE_DAGS_SUCCESS',
  FetchSchedules = 'CLIENT_ADMIN_TENANT_INTEGRATIONS_FETCH_SCHEDULES',
  UpdateSchedule = 'CLIENT_ADMIN_TENANT_INTEGRATIONS_UPDATE_SCHEDULE'
}

export enum ClientAdminTenantActionType {
  ResetState = 'CLIENT_ADMIN_TENANT_RESET_STATE',
  FetchSuccess = 'CLIENT_ADMIN_TENANT_FETCH_SUCCESS',
  SaveSuccess = 'CLIENT_ADMIN_TENANT_SAVE_SUCCESS',
  EditSuccess = 'CLIENT_ADMIN_TENANT_EDIT_SUCCESS'
}

export enum ClientAdminTenantSFTPConfigurationActionType {
  IsFetching = 'CLIENT_ADMIN_TENANT_SFTP_CONFIGURATION_IS_FETCHING',
  IsLoading = 'CLIENT_ADMIN_TENANT_SFTP_CONFIGURATION_IS_LOADING',
  FetchSuccess = 'CLIENT_ADMIN_TENANT_SFTP_CONFIGURATION_FETCH_SUCCESS',
  FetchError = 'CLIENT_ADMIN_TENANT_SFTP_CONFIGURATION_FETCH_ERROR',
  MutationError = 'CLIENT_ADMIN_TENANT_SFTP_CONFIGURATION_MUTATION_ERROR',
  SaveSuccess = 'CLIENT_ADMIN_TENANT_SFTP_CONFIGURATION_SAVE_SUCCESS',
  AddConnection = 'CLIENT_ADMIN_TENANT_SFTP_CONFIGURATION_ADD_CONNECTION',
  ResetConnection = 'CLIENT_ADMIN_TENANT_SFTP_CONFIGURATION_RESET_CONNECTION',
  ResetState = 'CLIENT_ADMIN_TENANT_SFTP_CONFIGURATION_RESET_STATE',
  OnSftpChange = 'CLIENT_ADMIN_TENANT_SFTP_CONFIGURATION_ON_SFTP_CHANGE',
  SetValidationErrors = 'CLIENT_ADMIN_TENANT_SFTP_CONFIGURATION_SET_VALIDATION_ERRORS',
  ToggleEncryption = 'CLIENT_ADMIN_TENANT_SFTP_CONFIGURATION_TOGGLE_ENCRYPTION',
  DeleteSuccess = 'CLIENT_ADMIN_TENANT_SFTP_CONFIGURATION_DELETE_SUCCESS',
  SetParsedData = 'CLIENT_ADMIN_TENANT_SFTP_CONFIGURATION_SET_PARSED_DATA_HEADERS'
}

export enum ClientAdminTenantSFTPConnectionActionType {
  IsFetching = 'CLIENT_ADMIN_TENANT_SFTP_CONNECTION_IS_FETCHING',
  IsLoading = 'CLIENT_ADMIN_TENANT_SFTP_CONNECTION_IS_LOADING',
  FetchAllSuccess = 'CLIENT_ADMIN_TENANT_SFTP_CONNECTION_FETCH_ALL_SUCCESS',
  FetchOneSuccess = 'CLIENT_ADMIN_TENANT_SFTP_CONNECTION_FETCH_ONE_SUCCESS',
  FetchError = 'CLIENT_ADMIN_TENANT_SFTP_CONNECTION_FETCH_ERROR',
  MutationError = 'CLIENT_ADMIN_TENANT_SFTP_CONNECTION_MUTATION_ERROR',
  SaveSuccess = 'CLIENT_ADMIN_TENANT_SFTP_CONNECTION_SAVE_SUCCESS',
  ResetConnection = 'CLIENT_ADMIN_TENANT_SFTP_CONNECTION_RESET_CONNECTION',
  ResetState = 'CLIENT_ADMIN_TENANT_SFTP_CONNECTION_RESET_STATE',
  OnChange = 'CLIENT_ADMIN_TENANT_SFTP_CONNECTION_ON_CHANGE',
  SetValidationErrors = 'CLIENT_ADMIN_TENANT_SFTP_CONNECTION_SET_VALIDATION_ERRORS',
  SetFormToggleState = 'CLIENT_ADMIN_TENANT_SFTP_CONNECTION_SET_FORM_TOGGLE_STATE'
}

export enum ClientAdminTenantSFTPGpgActionType {
  IsFetching = 'CLIENT_ADMIN_TENANT_SFTP_GPG_IS_FETCHING',
  IsLoading = 'CLIENT_ADMIN_TENANT_SFTP_GPG_IS_LOADING',
  FetchAllSuccess = 'CLIENT_ADMIN_TENANT_SFTP_GPG_FETCH_ALL_SUCCESS',
  FetchOneSuccess = 'CLIENT_ADMIN_TENANT_SFTP_GPG_FETCH_ONE_SUCCESS',
  FetchError = 'CLIENT_ADMIN_TENANT_SFTP_GPG_FETCH_ERROR',
  MutationError = 'CLIENT_ADMIN_TENANT_SFTP_GPG_MUTATION_ERROR',
  OnChange = 'CLIENT_ADMIN_TENANT_SFTP_GPG_ON_CHANGE',
  SaveSuccess = 'CLIENT_ADMIN_TENANT_SFTP_GPG_SAVE_SUCCESS',
  ToggleShowAddNew = 'CLIENT_ADMIN_TENANT_SFTP_GPG_TOGGLE_SHOW_ADD_NEW',
  SetValidationErrors = 'CLIENT_ADMIN_TENANT_SFTP_GPG_SET_VALIDATION_ERRORS',
  ResetState = 'CLIENT_ADMIN_TENANT_SFTP_GPG_RESET_STATE',
  DeleteSuccess = 'CLIENT_ADMIN_TENANT_SFTP_GPG_DELETE_SUCCESS'
}

export enum ClientAdminTenantSalesforceConfigurationActionType {
  IsFetching = 'CLIENT_ADMIN_TENANT_SALESFORCE_CONFIGURATION_IS_FETCHING',
  IsLoading = 'CLIENT_ADMIN_TENANT_SALESFORCE_CONFIGURATION_IS_LOADING',
  FetchOneSuccess = 'CLIENT_ADMIN_TENANT_SALESFORCE_CONFIGURATION_FETCH_ONE_SUCCESS',
  FetchError = 'CLIENT_ADMIN_TENANT_SALESFORCE_CONFIGURATION_FETCH_ERROR',
  MutationError = 'CLIENT_ADMIN_TENANT_SALESFORCE_CONFIGURATION_MUTATION_ERROR',
  ConfigOnChange = 'CLIENT_ADMIN_TENANT_SALESFORCE_CONFIGURATION_CONFIG_ON_CHANGE',
  SaveSuccess = 'CLIENT_ADMIN_TENANT_SALESFORCE_CONFIGURATION_SAVE_SUCCESS',
  SetValidationErrors = 'CLIENT_ADMIN_TENANT_SALESFORCE_CONFIGURATION_SET_VALIDATION_ERRORS',
  ResetState = 'CLIENT_ADMIN_TENANT_SALESFORCE_CONFIGURATION_RESET_STATE',
  DeleteSuccess = 'CLIENT_ADMIN_TENANT_SALESFORCE_CONFIGURATION_DELETE_SUCCESS'
}

export enum ClientAdminTenantTicketmasterConfigurationActionType {
  IsFetching = 'CLIENT_ADMIN_TENANT_TICKETMASTER_CONFIGURATION_IS_FETCHING',
  IsLoading = 'CLIENT_ADMIN_TENANT_TICKETMASTER_CONFIGURATION_IS_LOADING',
  FetchOneSuccess = 'CLIENT_ADMIN_TENANT_TICKETMASTER_CONFIGURATION_FETCH_ONE_SUCCESS',
  FetchError = 'CLIENT_ADMIN_TENANT_TICKETMASTER_CONFIGURATION_FETCH_ERROR',
  MutationError = 'CLIENT_ADMIN_TENANT_TICKETMASTER_CONFIGURATION_MUTATION_ERROR',
  SaveSuccess = 'CLIENT_ADMIN_TENANT_TICKETMASTER_CONFIGURATION_SAVE_SUCCESS',
  SetValidationErrors = 'CLIENT_ADMIN_TENANT_TICKETMASTER_CONFIGURATION_SET_VALIDATION_ERRORS',
  ResetState = 'CLIENT_ADMIN_TENANT_TICKETMASTER_CONFIGURATION_RESET_STATE',
  OnChange = 'CLIENT_ADMIN_TENANT_TICKETMASTER_CONFIGURATION_ON_CHANGE',
  DeleteSuccess = 'CLIENT_ADMIN_TENANT_TICKETMASTER_CONFIGURATION_DELETE_SUCCESS'
}
