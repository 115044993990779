import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { lazyMinLoadTime } from '../../../utils/codeSplitting';
import { Box } from '@mui/material';
import IndexPaperPageContent from '../../../styled/IndexPaperPageContent';
import { ModuleSubPath } from '../../../models/module';
import { Assignment, Person } from '@mui/icons-material';
import Roles from '../../../rbac/roles';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { UserAdminRoutes } from '../models/UserAdminRoutes';
import { multiRouteCreator } from '../../../utils/router';
import FeatureFlags from '../../../launchDarkly/featureFlags';
import { useSelector } from 'react-redux';
import { selectCurrentAccount, selectIsCompanyTenant } from '../../../auth/AccountInfoSlice';

export const mainPaths: ModuleSubPath[] = [
  {
    url: UserAdminRoutes.UserAdmin,
    name: 'Users',
    icon: <Person />,
    component: lazyMinLoadTime(() => import('./UserManagement')),
    permissions: {
      roles: [Roles.GlobalAdmin, Roles.Administrator],
      features: [],
      ldFlag: FeatureFlags.UserAdmin
    },
    exact: true,
    childRoutes: [
      {
        url: `${UserAdminRoutes.UserAdmin}/invite`,
        name: 'Invite Users',
        icon: <Person />,
        component: lazyMinLoadTime(() => import('./InviteUsers')),
        permissions: {
          roles: [Roles.GlobalAdmin, Roles.Administrator],
          ldFlag: FeatureFlags.UserAdmin,
          features: []
        },
        exact: true
      }
    ]
  },
  {
    url: UserAdminRoutes.Roles,
    name: 'Roles',
    icon: <Assignment />,
    component: lazyMinLoadTime(() => import('./UserAdminRoleManagement')),
    permissions: {
      roles: [Roles.GlobalAdmin, Roles.Administrator],
      ldFlag: FeatureFlags.UserAdmin,
      features: []
    },
    exact: true
  }
];

const UserAdminIndex: React.FunctionComponent = () => {
  const { path: modulePath } = useRouteMatch();
  const accountInfo = useSelector(selectCurrentAccount);
  const isCompanyTenant = useSelector(selectIsCompanyTenant);
  const ldFlags = useFlags();

  return (
    <Box id="org-admin-main" sx={{ height: '100%' }}>
      <IndexPaperPageContent id="main-container">
        <Switch>
          <Route exact path={modulePath}>
            <Redirect to={`${modulePath}/${UserAdminRoutes.UserAdmin}`} />
          </Route>
          {multiRouteCreator(mainPaths, modulePath, accountInfo, ldFlags, isCompanyTenant)}
          <Redirect to="/" />
        </Switch>
      </IndexPaperPageContent>
    </Box>
  );
};

export default UserAdminIndex;
