export enum SingleSignOnActionType {
  SaveInit = 'SINGLE_SIGN_ON_SAVE_INIT',
  OpenIdOnChange = 'SINGLE_SIGN_ON_OPEN_ID_ON_CHANGE',
  SamlOnChange = 'SINGLE_SIGN_ON_SAML_ON_CHANGE',
  ResetState = 'SINGLE_SIGN_ON_OPEN_ID_CLEAR_FORM',
  MutationError = 'SINGLE_SIGN_ON_MUTATION_ERROR',
  ShowModal = 'SINGLE_SIGN_ON_SHOW_MODAL',
  CloseModal = 'SINGLE_SIGN_ON_CLOSE_MODAL',
  SetForm = 'SINGLE_SIGN_ON_SET_FORM'
}
