import React from 'react';
import { Box, Dialog, Divider, Link, ListItemIcon, Menu, MenuItem } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { Help, LogoutRounded } from '@mui/icons-material';
import { removeCurrentTenantIdFromSessionStorage, setCurrentTenantIdToSessionStorage } from '../utils/sessionStorage';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { useMsal } from '@azure/msal-react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAccounts, selectCurrentAccount, selectIsGlobalAdmin, setCurrentAccount } from '../auth/AccountInfoSlice';
import TenantSwitcher from './TenantSwitcher';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { TenantSwitcherMenuItem } from './TenantSwitcherMenuItem';
import history from '../history';
import {
  useIntegrationMetadataLogoutSessionMutation,
  useTenantMetadataLogoutSessionMutation,
  useUsersLogoutSessionMutation
} from '../apis/SessionLogoutApiSlice';

const StyledAvatarBox = styled(Box)(({ theme }) => ({
  padding: 4
}));

const AccountMenu: React.FunctionComponent = () => {
  const accountInfo = useSelector(selectCurrentAccount);
  const allAccounts = useSelector(selectAccounts);
  const isMultiTenant = allAccounts.length > 1;
  const isGlobalAdmin = useSelector(selectIsGlobalAdmin);
  const dispatch = useDispatch();
  const [integrationMetadataLogout] = useIntegrationMetadataLogoutSessionMutation();
  const [tenantMetadataLogout] = useTenantMetadataLogoutSessionMutation();
  const [usersLogout] = useUsersLogoutSessionMutation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showTenantSwitcher, setShowTenantSwitcher] = React.useState<boolean>(false);
  const open = Boolean(anchorEl);
  const { instance } = useMsal();
  const ldFlags = useFlags();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    await integrationMetadataLogout();
    await tenantMetadataLogout();
    await usersLogout();
    removeCurrentTenantIdFromSessionStorage();
    await instance.logoutRedirect();
  };

  const handleOnSwitch = (tenantId: string) => {
    dispatch(setCurrentAccount(tenantId));
    setCurrentTenantIdToSessionStorage(tenantId);
    history.push('/home');
    closeTenantSwitcher();
  };

  const openTenantSwitcher = () => {
    setShowTenantSwitcher(true);
  };

  const closeTenantSwitcher = () => {
    setShowTenantSwitcher(false);
  };

  return (
    <>
      <Dialog open={showTenantSwitcher} fullWidth>
        <TenantSwitcher onSwitch={handleOnSwitch} onCancel={closeTenantSwitcher} />
      </Dialog>
      <IconButton
        onClick={handleClick}
        size="small"
        aria-controls={open ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        <StyledAvatarBox color="inherit">
          <Avatar src={accountInfo.picture} alt="My Avatar" />
        </StyledAvatarBox>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'no-wrap',
            maxWidth: '15rem',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem>
          <ListItemIcon>
            <Avatar src={accountInfo.picture} alt="My Avatar" />
          </ListItemIcon>
          <Box overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
            {accountInfo.name}
          </Box>
        </MenuItem>
        <MenuItem>
          <Box overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
            {accountInfo.email}
          </Box>
        </MenuItem>
        <Divider />
        <TenantSwitcherMenuItem isMultiTenant={isMultiTenant} isGlobalAdmin={isGlobalAdmin} ldFlags={ldFlags} onClick={openTenantSwitcher} />
        <Link href="https://aqathletics.zendesk.com/hc/en-us" target="_blank" color="inherit" underline="none">
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <Help fontSize="small" color="action" />
            </ListItemIcon>
            Help
          </MenuItem>
        </Link>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <LogoutRounded fontSize="small" color="action" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};

export default AccountMenu;
