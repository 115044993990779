import ReducerDefinition from '../../../interfaces/ReducerDefinition';
import { ClientAdminIntegrationEndpointsActionType } from '../actions/enums';
import { ReduxInitAction } from '../../../typings';
import IntegrationEndpoint from '../models/IntegrationEndpoint';
import { ClientAdminIntegrationEndpointsAction } from '../actions/clientAdminIntegrationEndpoints';

export interface ClientAdminIntegrationEndpointsState {
  isFetching: boolean;
  isLoading: boolean;
  integrationEndpoints: IntegrationEndpoint[];
  orgId: string;
  fetchError: any;
  mutationError: any;
}

export const defaultClientAdminIntegrationEndpointsState: ClientAdminIntegrationEndpointsState = {
  isFetching: false,
  isLoading: false,
  integrationEndpoints: [] as IntegrationEndpoint[],
  orgId: '',
  fetchError: '',
  mutationError: ''
};

const clientAdminIntegrationEndpointsReducer: ReducerDefinition = {
  reducerName: 'integration_endpoints',
  reducerFunction: (
    state: ClientAdminIntegrationEndpointsState = defaultClientAdminIntegrationEndpointsState,
    action: ClientAdminIntegrationEndpointsAction | ReduxInitAction
  ): ClientAdminIntegrationEndpointsState => {
    switch (action.type) {
      case ClientAdminIntegrationEndpointsActionType.FetchInit:
        return {
          ...state,
          isFetching: true
        } as ClientAdminIntegrationEndpointsState;
      case ClientAdminIntegrationEndpointsActionType.IsLoading:
        return {
          ...state,
          isLoading: true
        } as ClientAdminIntegrationEndpointsState;
      case ClientAdminIntegrationEndpointsActionType.FetchSuccess:
        return {
          ...state,
          isFetching: false,
          integrationEndpoints: action.payload
        } as ClientAdminIntegrationEndpointsState;
      case ClientAdminIntegrationEndpointsActionType.SaveSuccess:
        return {
          ...state,
          isLoading: false,
          integrationEndpoints: action.payload
        } as ClientAdminIntegrationEndpointsState;
      case ClientAdminIntegrationEndpointsActionType.FetchError:
        return {
          ...state,
          isFetching: false,
          fetchError: action.payload
        } as ClientAdminIntegrationEndpointsState;
      case ClientAdminIntegrationEndpointsActionType.MutationError:
        return {
          ...state,
          isLoading: false,
          mutationError: action.payload
        } as ClientAdminIntegrationEndpointsState;
      case ClientAdminIntegrationEndpointsActionType.OnChange:
        return {
          ...state,
          integrationEndpoints: action.payload
        } as ClientAdminIntegrationEndpointsState;
      default:
        return state;
    }
  }
};

export default clientAdminIntegrationEndpointsReducer;
