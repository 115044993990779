import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { lazyMinLoadTime } from '../../../utils/codeSplitting';
import { Box } from '@mui/material';
import IndexPaperPageContent from '../../../styled/IndexPaperPageContent';
import { ModuleSubPath } from '../../../models/module';
import Roles from '../../../rbac/roles';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { multiRouteCreator } from '../../../utils/router';
import FeatureFlags from '../../../launchDarkly/featureFlags';
import { useSelector } from 'react-redux';
import { selectCurrentAccount, selectIsCompanyTenant } from '../../../auth/AccountInfoSlice';
import Features from '../../../rbac/features';
import { TicketTaggingRoutesEnum } from '../models/Routes';

export const mainPaths: ModuleSubPath[] = [
  {
    url: TicketTaggingRoutesEnum.SEASON_SUMMARY_DASHBOARD,
    name: 'Season Summary Dashboard',
    component: lazyMinLoadTime(() => import('./season-summary-dashboard/SeasonSummaryDashboard')),
    permissions: {
      roles: [Roles.GlobalAdmin, Roles.Administrator, Roles.TicketTagging],
      features: [Features.TicketTagging],
      ldFlag: FeatureFlags.TicketTagging,
      eitherRolesOrFeatures: true
    },
    exact: true,
    childRoutes: [
      {
        url: TicketTaggingRoutesEnum.SEASON_CONFIGURATION,
        name: 'Season Data',
        component: lazyMinLoadTime(() => import('./season-configuration/SeasonConfigurationTable')),
        permissions: {
          roles: [Roles.GlobalAdmin, Roles.Administrator, Roles.TicketTagging],
          features: [Features.TicketTagging],
          ldFlag: FeatureFlags.TicketTagging,
          eitherRolesOrFeatures: true
        },
        exact: true
      },
      {
        url: TicketTaggingRoutesEnum.EVENT,
        name: 'Event',
        component: lazyMinLoadTime(() => import('./season/SeasonDashboard')),
        permissions: {
          roles: [Roles.GlobalAdmin, Roles.Administrator, Roles.TicketTagging],
          features: [Features.TicketTagging],
          ldFlag: FeatureFlags.TicketTagging,
          eitherRolesOrFeatures: true
        }
      }
    ]
  }
];

const TicketTaggingIndex: React.FunctionComponent = () => {
  const { path: modulePath } = useRouteMatch();
  const accountInfo = useSelector(selectCurrentAccount);
  const isCompanyTenant = useSelector(selectIsCompanyTenant);
  const ldFlags = useFlags();

  return (
    <Box id="ticket-tagging-main" sx={{ height: '100%' }}>
      <IndexPaperPageContent id="main-container">
        <Switch>
          <Route exact path={modulePath}>
            <Redirect to={`${modulePath}/season-summary-dashboard`} />
          </Route>
          {multiRouteCreator(mainPaths, modulePath, accountInfo, ldFlags, isCompanyTenant)}
          <Redirect to="/" />
        </Switch>
      </IndexPaperPageContent>
    </Box>
  );
};

export default TicketTaggingIndex;
