import { api } from '../../../shared/api';
import { providesList } from '../../../utils/rtkQueryHelperFunctions';
import FeatureModel, { FeatureModelPayload } from '../../user-admin/models/FeatureModel';
import TenantModel from '../models/TenantModel';
import { AddTenantsToFeaturePayload } from '../models/AddTenantsToFeaturePayload';

const usersServiceApiUrl = process.env.REACT_APP_USERS_SERVICE_BASE_URL;
const ClientAdminFeatureApiSlice = api.enhanceEndpoints({ addTagTypes: ['Feature', 'FeatureTenants', 'Report'] }).injectEndpoints({
  endpoints: builder => ({
    fetchAllTenantFeatures: builder.query<FeatureModel[], string>({
      query: (tenantId: string) => `${usersServiceApiUrl}/api/features/tenant/${tenantId}`,
      providesTags: result => providesList(result, 'Feature')
    }),
    fetchAllTenantReports: builder.query<FeatureModel[], string>({
      query: (tenantId: string) => `${usersServiceApiUrl}/api/reports/tenant/${tenantId}`,
      providesTags: result => providesList(result, 'Report')
    }),
    fetchTenantUserReports: builder.query<FeatureModel[], { tenantId: string; userId: string }>({
      query: ({ tenantId, userId }) => `${usersServiceApiUrl}/api/reports/tenant/${tenantId}/user/${userId}`,
      providesTags: result => providesList(result, 'Report')
    }),
    fetchAllReports: builder.query<FeatureModel[], string>({
      query: (tenantId: string) => `${usersServiceApiUrl}/api/reports/tenant/${tenantId}/all`,
      providesTags: result => providesList(result, 'Report')
    }),
    fetchReport: builder.query<FeatureModel, string>({
      query: (reportId: string) => `${usersServiceApiUrl}/api/reports/${reportId}`,
      providesTags: result =>
        result
          ? [
              {
                type: 'Report' as const,
                id: result.id
              },
              { type: 'Report' as const, id: 'LIST' }
            ]
          : [{ type: 'Report' as const, id: 'LIST' }]
    }),
    fetchFeature: builder.query<FeatureModel, string>({
      query: (featureId: string) => `${usersServiceApiUrl}/api/features/${featureId}`,
      providesTags: result =>
        result
          ? [
              {
                type: 'Feature' as const,
                id: result.id
              },
              { type: 'Feature' as const, id: 'LIST' }
            ]
          : [{ type: 'Feature' as const, id: 'LIST' }]
    }),
    fetchAllFeatureTenants: builder.query<string[], string>({
      query: (reportId: string) => `${usersServiceApiUrl}/api/features/${reportId}/tenants`,
      providesTags: result =>
        result
          ? [
              ...result.map((id: string) => ({
                type: 'FeatureTenants' as const,
                id: id as string
              })),
              { type: 'FeatureTenants' as const, id: 'LIST' }
            ]
          : [{ type: 'FeatureTenants' as const, id: 'LIST' }]
    }),
    updateFeatureTenants: builder.mutation<TenantModel[], AddTenantsToFeaturePayload>({
      query: (payload: AddTenantsToFeaturePayload) => ({
        url: `${usersServiceApiUrl}/api/features/${payload.featureId}/tenants`,
        method: 'POST',
        body: payload
      }),
      invalidatesTags: [
        { type: 'FeatureTenants', id: 'LIST' },
        { type: 'Feature', id: 'LIST' }
      ]
    }),
    saveFeature: builder.mutation<FeatureModel, FeatureModelPayload>({
      query: (payload: FeatureModelPayload) => ({
        url: `${usersServiceApiUrl}/api/features`,
        method: 'POST',
        body: payload
      }),
      invalidatesTags: [
        { type: 'Feature', id: 'LIST' },
        { type: 'Report', id: 'LIST' }
      ]
    }),
    updateFeature: builder.mutation<FeatureModel, FeatureModel>({
      query: (payload: FeatureModel) => ({
        url: `${usersServiceApiUrl}/api/features/${payload.id}`,
        method: 'PUT',
        body: payload
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Feature', id: arg.id },
        { type: 'Report', id: arg.id }
      ]
    }),
    deleteFeature: builder.mutation<void, string>({
      query: (featureId: string) => ({
        url: `${usersServiceApiUrl}/api/features/${featureId}`,
        method: 'DELETE'
      }),
      invalidatesTags: () => [
        { type: 'Feature', id: 'LIST' },
        { type: 'Report', id: 'LIST' }
      ]
    })
  })
});

export const {
  useFetchAllTenantFeaturesQuery,
  useFetchTenantUserReportsQuery,
  useFetchAllTenantReportsQuery,
  useFetchAllReportsQuery,
  useFetchReportQuery,
  useFetchFeatureQuery,
  useFetchAllFeatureTenantsQuery,
  useUpdateFeatureTenantsMutation,
  useSaveFeatureMutation,
  useUpdateFeatureMutation,
  useDeleteFeatureMutation
} = ClientAdminFeatureApiSlice;
