import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';
import { AccountInfoType } from '../../auth/AccountInfoSlice';
import SsbModule, { ModuleSubPath } from '../../models/module';
import { removeWhiteSpaces } from '../../utils/regex';
import { navClasses } from './Navigator';

export interface NavigatorListItemProps {
  module: SsbModule;
  onListItemClick: (module: SsbModule, path?: ModuleSubPath) => void;
  pathName: string;
  accountInfo: AccountInfoType;
}

const NavigatorListItem: React.FunctionComponent<NavigatorListItemProps> = (props: NavigatorListItemProps) => {
  const { module, onListItemClick, pathName } = props;

  return (
    <li key={module.name} id={removeWhiteSpaces(module.name)}>
      <ListItemButton
        component={Link}
        to={module.path}
        selected={pathName.includes(module.path)}
        onClick={() => {
          onListItemClick(module);
        }}
        className={clsx(navClasses.item, navClasses.listItem, pathName.includes(module.path) ? navClasses.itemActiveItem : '')}
      >
        <ListItemIcon>{module.icon}</ListItemIcon>
        <ListItemText primary={module.name} />
      </ListItemButton>
    </li>
  );
};

export default NavigatorListItem;
