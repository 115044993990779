import React, { useContext, useState } from 'react';
import { setIsDrawerOpenDesktop, setIsDrawerOpenMobile, setIsPinnedMenuIcon } from '../../actions';
import { drawerWidth } from '../Paperbase';
import MuiDrawer, { DrawerProps } from '@mui/material/Drawer';
import { CSSObject, styled, Theme } from '@mui/material/styles';
import { CSSProperties } from '@mui/styled-engine';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import DrawerMobileContext from './context/drawerMobileContext';
import { Lock } from '@mui/icons-material';

interface DrawerComponentProps extends Omit<DrawerProps, 'classes'> {
  children?: React.ReactNode;
  isDrawerOpenDesktop: boolean;
  isDrawerOpenMobile: boolean;
  isPinnedMenuIcon: boolean;
  handleDrawerToggleDesktop: typeof setIsDrawerOpenDesktop;
  handleDrawerToggleMobile: typeof setIsDrawerOpenMobile;
  handleToggleMenuIcon: typeof setIsPinnedMenuIcon;
}

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden',
  boxShadow: '4px 0px 10px rgb(0 0 0 / 60%)' //Add a box shadow to indicate the open status of the drawer
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('md')]: {
    width: `calc(${theme.spacing(9)} + 1px)`
  }
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  color: theme.palette.primary.contrastText,
  // so content actually stays below the app bar and the height matches the app bar header
  ...(theme.mixins.toolbar as CSSProperties),
  '&.hovered': {
    cursor: 'pointer'
  }
}));

const scrollbarThumbColor = '#F5F5F5';

const Drawer = styled(MuiDrawer, { shouldForwardProp: prop => prop !== 'open' })(({ theme, open }) => ({
  flexShrink: 0,
  whiteSpace: 'nowrap',
  '& .MuiDrawer-paper::-webkit-scrollbar-thumb': {
    backgroundColor: scrollbarThumbColor,
    borderRadius: '0.1em'
  },
  '& .MuiDrawer-paper::-webkit-scrollbar': {
    width: '0.5em'
  },
  ...(open && {
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    '& .MuiDrawer-paper': closedMixin(theme),
    // hide scrollbar when menu is closed
    '& .MuiDrawer-paper::-webkit-scrollbar': {
      display: 'none'
    }
  })
}));

const MobileDrawer = styled(MuiDrawer)(({ theme }) => ({
  flexShrink: 0,
  whiteSpace: 'nowrap',
  '& .MuiDrawer-paper::-webkit-scrollbar-thumb': {
    backgroundColor: scrollbarThumbColor,
    borderRadius: '0.1em'
  },
  '& .MuiDrawer-paper::-webkit-scrollbar': {
    width: '0.5em'
  }
}));

export function DrawerComponent(props: DrawerComponentProps) {
  const {
    isDrawerOpenDesktop,
    isDrawerOpenMobile,
    isPinnedMenuIcon,
    handleDrawerToggleDesktop,
    handleDrawerToggleMobile,
    handleToggleMenuIcon,
    ...other
  } = props;
  const { isMobile } = useContext(DrawerMobileContext);

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleDrawerToggle = (isOpen: boolean) => {
    if (!isPinnedMenuIcon) {
      handleDrawerToggleDesktop(isOpen);
    }
  };

  const renderDrawerHeader = () => {
    const handleMenuIconClick = () => {
      if (isPinnedMenuIcon) {
        handleToggleMenuIcon(false);
      } else {
        handleToggleMenuIcon(true);
      }
    };

    const menuIconProps = {
      id: 'navigation-menu-unlocked-icon',
      onClick: handleMenuIconClick
    };

    const menuOpenIconProps = {
      id: 'navigation-menu-open-icon',
      onClick: handleMenuIconClick
    };

    const renderMenuIcon = () => {
      if (isPinnedMenuIcon && isDrawerOpenDesktop) {
        return <Lock {...menuIconProps} />;
      } else if (isPinnedMenuIcon && !isDrawerOpenDesktop) {
        return <MenuOpenIcon {...menuOpenIconProps} />;
      } else {
        return <MenuIcon {...menuIconProps} />;
      }
    };

    return (
      <DrawerHeader className={`menu-icon ${isHovered ? 'hovered' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {renderMenuIcon()}
      </DrawerHeader>
    );
  };

  if (isMobile) {
    return (
      <MobileDrawer variant="permanent" {...other} onTouchStart={() => handleDrawerToggleMobile()}>
        {renderDrawerHeader()}
        {props.children}
      </MobileDrawer>
    );
  }

  return (
    <Drawer
      variant="permanent"
      onMouseEnter={() => handleDrawerToggle(true)}
      onMouseLeave={() => handleDrawerToggle(false)}
      onTouchStart={() => handleDrawerToggle(true)}
      {...other}
    >
      {renderDrawerHeader()}
      {props.children}
    </Drawer>
  );
}

const mapStateToProps = (state: any) => {
  return {
    isDrawerOpenDesktop: state.navigator.isDrawerOpenDesktop,
    isDrawerOpenMobile: state.navigator.isDrawerOpenMobile,
    isPinnedMenuIcon: state.navigator.isPinnedMenuIcon
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, never, any> | Dispatch<any>) => ({
  handleDrawerToggleDesktop: (isOpen: boolean) => dispatch(setIsDrawerOpenDesktop(isOpen)),
  handleDrawerToggleMobile: () => dispatch(setIsDrawerOpenMobile()),
  handleToggleMenuIcon: (isOpen: boolean) => dispatch(setIsPinnedMenuIcon(isOpen))
});

export default connect(mapStateToProps, mapDispatchToProps)(DrawerComponent);
