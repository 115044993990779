import React from 'react';
import { CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledSpinnerDivWrapper = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translateX(-50%)'
}));

function Loading() {
  return (
    <StyledSpinnerDivWrapper>
      <CircularProgress />
    </StyledSpinnerDivWrapper>
  );
}

export default Loading;
