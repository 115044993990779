import { AuthenticationResult, EventMessage, EventType, PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './authConfig';

const msalInstance = new PublicClientApplication(msalConfig);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const configureMsalInstance = async (): Promise<void> => {
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
  }

  msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload != null) {
      const payload = event.payload as AuthenticationResult;
      const account = payload.account;
      msalInstance.setActiveAccount(account);
    }
  });
};

export const authTimeoutKey = 'authTimeout';

export const handleTokenAcquisitionFailure = async () => {
  sessionStorage.setItem(authTimeoutKey, Date.now().toString());
  await msalInstance.logoutRedirect();
};

export default msalInstance;
