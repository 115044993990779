import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { lazyMinLoadTime } from '../../../utils/codeSplitting';
import { Box } from '@mui/material';
import IndexPaperPageContent from '../../../styled/IndexPaperPageContent';
import { ModuleSubPath } from '../../../models/module';
import { BarChart, Power, Settings } from '@mui/icons-material';
import Roles from '../../../rbac/roles';
import FeatureFlags from '../../../launchDarkly/featureFlags';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ClientAdminRoutes } from '../models/ClientAdminRoutes';
import { multiRouteCreator } from '../../../utils/router';
import Features from '../../../rbac/features';
import { useSelector } from 'react-redux';
import { selectCurrentAccount, selectIsCompanyTenant } from '../../../auth/AccountInfoSlice';

export const mainPaths: ModuleSubPath[] = [
  {
    url: ClientAdminRoutes.Tenants,
    name: 'Tenants',
    icon: <Settings />,
    component: lazyMinLoadTime(() => import('./ClientAdministration')),
    permissions: {
      roles: [Roles.GlobalAdmin],
      features: [Features.ClientAdmin],
      ldFlag: FeatureFlags.ClientAdmin,
      isCompanyOrgOnly: true
    },
    exact: true,
    childRoutes: [
      {
        url: `${ClientAdminRoutes.Tenants}/new-tenant`,
        name: 'Create Tenant',
        permissions: {
          roles: [Roles.GlobalAdmin],
          ldFlag: FeatureFlags.ClientAdmin,
          isCompanyOrgOnly: true
        },
        component: lazyMinLoadTime(() => import('./NewTenant'))
      },
      {
        url: `${ClientAdminRoutes.Tenants}/:id/integrations/add-new`,
        name: 'Add Integration',
        component: lazyMinLoadTime(() => import('./AddTenantIntegration')),
        permissions: {
          roles: [Roles.GlobalAdmin],
          ldFlag: FeatureFlags.Integrations,
          isCompanyOrgOnly: true
        },
        exact: true
      },
      {
        url: `${ClientAdminRoutes.Tenants}/:id/integrations/:tenantIntegrationId`,
        name: 'Configure Integration',
        permissions: {
          roles: [Roles.GlobalAdmin],
          ldFlag: FeatureFlags.Integrations,
          isCompanyOrgOnly: true
        },
        component: lazyMinLoadTime(() => import('./TenantIntegrationConfiguration/TenantIntegrationConfiguration')),
        exact: true
      },
      {
        url: `${ClientAdminRoutes.Tenants}/:id/:tab`,
        name: 'Tenant Router',
        permissions: {
          roles: [Roles.GlobalAdmin],
          ldFlag: FeatureFlags.ClientAdmin,
          isCompanyOrgOnly: true
        },
        component: lazyMinLoadTime(() => import('./ViewTenant')),
        exact: true
      }
    ]
  },
  {
    url: ClientAdminRoutes.Integrations,
    name: 'Integrations',
    icon: <Power />,
    permissions: {
      roles: [Roles.GlobalAdmin],
      ldFlag: FeatureFlags.Integrations,
      isCompanyOrgOnly: true
    },
    component: lazyMinLoadTime(() => import('./Integrations')),
    exact: true,
    childRoutes: [
      {
        url: `${ClientAdminRoutes.Integrations}/:id`,
        name: 'Integration Tenants',
        permissions: {
          roles: [Roles.GlobalAdmin],
          ldFlag: FeatureFlags.Integrations,
          isCompanyOrgOnly: true
        },
        component: lazyMinLoadTime(() => import('./IntegrationTenants'))
      }
    ]
  },
  {
    url: ClientAdminRoutes.ClientAdminExternal,
    name: 'Legacy Admin',
    icon: <Settings />,
    component: lazyMinLoadTime(() => import('./ClientAdminExternal')),
    permissions: {
      roles: [Roles.GlobalAdmin],
      ldFlag: FeatureFlags.ClientAdmin,
      isCompanyOrgOnly: true
    },
    exact: true
  },
  {
    url: ClientAdminRoutes.Reports,
    name: 'Reports',
    icon: <BarChart />,
    component: lazyMinLoadTime(() => import('./Reports/TenantReports')),
    childRoutes: [
      {
        url: `${ClientAdminRoutes.Reports}/new-report`,
        name: 'Add a report',
        permissions: {
          roles: [Roles.GlobalAdmin, Roles.ReportManager],
          ldFlag: FeatureFlags.ClientAdmin,
          isCompanyOrgOnly: true
        },
        component: lazyMinLoadTime(() => import('./Reports/NewReport'))
      },
      {
        url: `${ClientAdminRoutes.Reports}/:reportId/tenants`,
        name: 'Report Tenants',
        component: lazyMinLoadTime(() => import('./Reports/ReportTenants')),
        permissions: {
          roles: [Roles.GlobalAdmin, Roles.ReportManager],
          ldFlag: FeatureFlags.ClientAdmin,
          isCompanyOrgOnly: true
        },
        exact: true
      },
      {
        url: `${ClientAdminRoutes.Reports}/:id`,
        name: 'Report Router',
        permissions: {
          roles: [Roles.GlobalAdmin, Roles.ReportManager],
          ldFlag: FeatureFlags.ClientAdmin,
          isCompanyOrgOnly: true
        },
        component: lazyMinLoadTime(() => import('./Reports/EditReport')),
        exact: true
      }
    ],
    permissions: {
      roles: [Roles.GlobalAdmin, Roles.ReportManager],
      ldFlag: FeatureFlags.ClientAdmin,
      isCompanyOrgOnly: true
    },
    exact: true
  }
];

const ClientAdminIndex: React.FunctionComponent = () => {
  const { path: modulePath } = useRouteMatch();
  const accountInfo = useSelector(selectCurrentAccount);
  const isCompanyTenant = useSelector(selectIsCompanyTenant);
  const ldFlags = useFlags();

  return (
    <Box id="client-admin-main" sx={{ height: '100%' }}>
      <IndexPaperPageContent id="main-container">
        <Switch>
          <Route exact path={modulePath}>
            <Redirect to={`${modulePath}/${ClientAdminRoutes.Tenants}`} />
          </Route>
          {multiRouteCreator(mainPaths, modulePath, accountInfo, ldFlags, isCompanyTenant)}
          <Redirect to="/" />
        </Switch>
      </IndexPaperPageContent>
    </Box>
  );
};

export default ClientAdminIndex;
