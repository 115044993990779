import { BaseQueryFn, createApi, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { findDefaultScopeByUrl } from '../authConfig';
import msalInstance, { handleTokenAcquisitionFailure } from '../msal-instance';
import { tenantIdHeaderKey } from '../utils/org';

export const baseQuery = fetchBaseQuery({
  prepareHeaders: async (headers, api) => {
    headers.set('Content-Type', 'application/json');
    headers.set('Ocp-Apim-Subscription-Key', process.env.REACT_APP_APIM_SUB_KEY!);
    const token = api.extra ?? undefined;
    if (token) headers.set('Authorization', `Bearer ${token}`);
    const tenantId = headers.get(tenantIdHeaderKey) ?? undefined;
    if (tenantId) headers.set(tenantIdHeaderKey, tenantId);
    return headers;
  }
});

const baseQueryWithToken: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args: any, api, extraOptions) => {
  const { url, meta } = typeof args === 'string' ? { url: args, meta: undefined } : args;
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    const defaultScope = findDefaultScopeByUrl(url);
    if (!defaultScope) {
      throw new Error('No default scope found for this endpoint');
    }
    const silentRequest = {
      scopes: defaultScope, // Replace with your API scopes
      account: accounts[0] // If there are multiple accounts, you need to choose which one to use
    };
    try {
      const silentResponse = await msalInstance.acquireTokenSilent(silentRequest);
      api.extra = silentResponse.accessToken;
    } catch (error) {
      // Fallback to an interactive method if silent acquisition fails
      if (!meta?.ignoreTokenAcquisitionFailure) {
        await handleTokenAcquisitionFailure();
      }
      console.error('Token acquisition failed, falling back to interactive method', error);
    }
  } else if (!meta?.ignoreTokenAcquisitionFailure) {
    await handleTokenAcquisitionFailure();
  }

  return baseQuery(args, api, extraOptions);
};

export const api = createApi({
  baseQuery: baseQueryWithToken,
  endpoints: builder => ({})
});
