enum Roles {
  Administrator = 'Administrator',
  GlobalAdmin = 'Global Admin',
  ReportManager = 'Report Manager',
  ReportViewer = 'Report Viewer',
  Segmentation = 'Segmentation',
  TicketTagging = 'Ticket Tagging',
  GRM = 'GRM'
}

export default Roles;
