import ReducerDefinition from '../../../interfaces/ReducerDefinition';
import { KeyStringAnyValue, ReduxInitAction } from '../../../typings';
import { ClientAdminTenantSFTPConfigurationAction } from '../actions/clientAdminTenantSFTPConfiguration';
import { ClientAdminTenantSFTPConfigurationActionType } from '../actions/enums';
import SFTPConfiguration from '../models/SFTPConfiguration';

export interface ClientAdminTenantSFTPConfigurationState {
  isFetching: boolean;
  isLoading: boolean;
  fetchError: any;
  mutationError: any;
  tenantIntegrationId: string;
  sftpConfiguration: SFTPConfiguration;
  useEncryption: boolean;
  validationErrors: {};
  parsedData: KeyStringAnyValue[];
}

export enum DelimiterValues {
  CSV = ',',
  TSV = '\\t'
}

export const defaultClientAdminTenantSFTPConfigurationState: ClientAdminTenantSFTPConfigurationState = {
  isFetching: false,
  isLoading: false,
  fetchError: '',
  mutationError: '',
  tenantIntegrationId: '',
  sftpConfiguration: {
    id: '',
    tenantIntegrationId: '',
    tenantSftpId: '',
    sftpArchiveDirectory: '',
    sftpDeleteFromSource: false,
    sftpLatestOnly: false,
    sftpOperation: 'GET',
    sourceDirectory: '',
    sourceFilename: '',
    blobDestination: '',
    tenantGpgId: '',
    active: true,
    scheduleId: '',
    fileDelimiter: ''
  } as SFTPConfiguration,
  useEncryption: false,
  validationErrors: {},
  parsedData: []
};

const clientAdminTenantSFTPConfigurationReducer: ReducerDefinition = {
  reducerName: 'tenant_sftp_configuration',
  reducerFunction: (
    state: ClientAdminTenantSFTPConfigurationState = defaultClientAdminTenantSFTPConfigurationState,
    action: ClientAdminTenantSFTPConfigurationAction | ReduxInitAction
  ): ClientAdminTenantSFTPConfigurationState => {
    switch (action.type) {
      case ClientAdminTenantSFTPConfigurationActionType.IsFetching:
        return {
          ...state,
          isFetching: true
        } as ClientAdminTenantSFTPConfigurationState;
      case ClientAdminTenantSFTPConfigurationActionType.IsLoading:
        return {
          ...state,
          isLoading: true
        } as ClientAdminTenantSFTPConfigurationState;
      case ClientAdminTenantSFTPConfigurationActionType.FetchSuccess:
        return {
          ...state,
          isFetching: false,
          sftpConfiguration: action.payload
        } as ClientAdminTenantSFTPConfigurationState;
      case ClientAdminTenantSFTPConfigurationActionType.FetchError:
        return {
          ...state,
          isFetching: false,
          fetchError: action.payload
        } as ClientAdminTenantSFTPConfigurationState;
      case ClientAdminTenantSFTPConfigurationActionType.MutationError:
        return {
          ...state,
          isLoading: false,
          mutationError: action.payload
        } as ClientAdminTenantSFTPConfigurationState;
      case ClientAdminTenantSFTPConfigurationActionType.AddConnection:
        return {
          ...state,
          sftpConfiguration: { ...state.sftpConfiguration, tenantSftpId: action.payload } as SFTPConfiguration
        } as ClientAdminTenantSFTPConfigurationState;
      case ClientAdminTenantSFTPConfigurationActionType.SaveSuccess:
        return {
          ...state,
          isLoading: false,
          sftpConfiguration: action.payload,
          validationErrors: defaultClientAdminTenantSFTPConfigurationState.validationErrors
        } as ClientAdminTenantSFTPConfigurationState;
      case ClientAdminTenantSFTPConfigurationActionType.DeleteSuccess:
        return {
          ...state,
          isLoading: false,
          isFetching: false,
          sftpConfiguration: { ...state.sftpConfiguration, active: false } as SFTPConfiguration,
          validationErrors: defaultClientAdminTenantSFTPConfigurationState.validationErrors
        } as ClientAdminTenantSFTPConfigurationState;
      case ClientAdminTenantSFTPConfigurationActionType.ResetConnection:
        return {
          ...state,
          sftpConfiguration: { ...state.sftpConfiguration, tenantSftpId: '' } as SFTPConfiguration,
          validationErrors: defaultClientAdminTenantSFTPConfigurationState.validationErrors
        } as ClientAdminTenantSFTPConfigurationState;
      case ClientAdminTenantSFTPConfigurationActionType.ResetState:
        return {
          ...defaultClientAdminTenantSFTPConfigurationState
        } as ClientAdminTenantSFTPConfigurationState;
      case ClientAdminTenantSFTPConfigurationActionType.OnSftpChange:
        const { key: sftpKey, value: sftpValue } = action.payload;
        return {
          ...state,
          sftpConfiguration: {
            ...state.sftpConfiguration,
            [sftpKey]: sftpValue
          }
        } as ClientAdminTenantSFTPConfigurationState;
      case ClientAdminTenantSFTPConfigurationActionType.ToggleEncryption:
        return {
          ...state,
          useEncryption: action.payload
        } as ClientAdminTenantSFTPConfigurationState;
      case ClientAdminTenantSFTPConfigurationActionType.SetParsedData:
        return {
          ...state,
          parsedData: action.payload
        } as ClientAdminTenantSFTPConfigurationState;
      case ClientAdminTenantSFTPConfigurationActionType.SetValidationErrors:
        return {
          ...state,
          isLoading: false,
          validationErrors: action.payload
        } as ClientAdminTenantSFTPConfigurationState;
      default:
        return state;
    }
  }
};

export default clientAdminTenantSFTPConfigurationReducer;
