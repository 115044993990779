import ReducerDefinition from '../interfaces/ReducerDefinition';
import { CombinedReducerDefinition } from '../typings';

//function can be re-used with other modules that have redux functionality
const setupModuleReducers = (reducerDefinitions: ReducerDefinition[], prefix: string) => {
  const reducers: CombinedReducerDefinition = {};
  //go through each reducer defintion
  reducerDefinitions.forEach((reducerDefinition: ReducerDefinition) => {
    reducerDefinition.reducerName = `${prefix}${reducerDefinition.reducerName}`; //update the reducer name of the current reducer definition object with a prefix so there are no collisions when reducer might have the same name after their prfix
    reducers[reducerDefinition.reducerName] = reducerDefinition.reducerFunction; //add the reducer function to the new reducer key, which consists of the reducer name with a prefix
  });
  return reducers; //return the built reducers so they can be combined when setting up the redux store
};

export default setupModuleReducers;
