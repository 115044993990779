export enum ClientAdminRoutes {
  ClientAdmin = 'client-admin',
  ClientAdminExternal = 'client-admin-external',
  NewTenant = 'new-tenant',
  Tenants = 'tenants',
  Tools = 'tools',
  Users = 'users',
  Integrations = 'integrations',
  Reports = 'reports',
  NewReport = 'new-report'
}
