import React, { useEffect, useMemo } from 'react';
import { LoadingModal } from '../modals/LoadingModal';
import { Alert, AlertColor, Box, BoxProps, Button, styled, Typography } from '@mui/material';
import { useMsal } from '@azure/msal-react';
import IndexPaperPageContent from '../../styled/IndexPaperPageContent';
import { AQLogo } from '../../assets/components/AQLogo';
import stadium_background from '../../assets/img/stadium_background.jpg';
import { InteractionStatus } from '@azure/msal-browser';
import { useHistory, useLocation } from 'react-router-dom';
import { authTimeoutKey } from '../../msal-instance';
import useQuery from '../../hooks/useQuery';

const BoxWithCityBackground = styled(Box)<BoxProps>(() => ({
  id: 'home-background-image-container',
  backgroundImage: `url(${stadium_background})`,
  backgroundSize: 'cover',
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column'
}));

const Login = () => {
  const { instance, inProgress, accounts } = useMsal();
  const [isLoading, setIsLoading] = React.useState(false);
  const history = useHistory();
  const location = useLocation();
  const query = useQuery();
  const error = query.get('error');
  const type = query.get('type');

  const getAlertType: AlertColor = useMemo(() => {
    switch (type) {
      case 'info':
        return 'info' as AlertColor;
      case 'error':
        return 'error' as AlertColor;
      case 'warning':
        return 'warning' as AlertColor;
      case 'success':
        return 'success' as AlertColor;
      default:
        return 'error' as AlertColor;
    }
  }, [type]);

  const authTimeout = sessionStorage.getItem(authTimeoutKey);

  useEffect(() => {
    if (accounts.length > 0) {
      // User is logged in
      const storedRedirectPath = sessionStorage.getItem('postLoginRedirectPath');
      if (storedRedirectPath) {
        history.push(storedRedirectPath);
        sessionStorage.removeItem('postLoginRedirectPath');
      }
    } else {
      // User is not logged in
      const urlWithoutOrigin = location.pathname + location.search + location.hash;
      sessionStorage.setItem('postLoginRedirectPath', urlWithoutOrigin);
    }
  }, [accounts.length, history, location.hash, location.pathname, location.search]);

  const handleSignIn = async () => {
    setIsLoading(true);
    sessionStorage.removeItem(authTimeoutKey);
    await instance.loginRedirect();
    await instance.handleRedirectPromise();
  };

  return (
    <IndexPaperPageContent id="login-main-container" sx={{ height: '100vh', margin: 0 }}>
      <BoxWithCityBackground>
        <AQLogo />
        <Typography variant="h4" textAlign="center">
          Welcome to Central Intelligence!
        </Typography>
        <Box p={2}>
          <Button variant="contained" onClick={handleSignIn} disabled={inProgress !== InteractionStatus.None && !isLoading}>
            Login
          </Button>
        </Box>
        {error && <Alert severity={getAlertType}>{error}</Alert>}
        {Boolean(authTimeout) && (
          <Typography variant="body2" textAlign="center">
            Your session has expired. Please, log in again.
          </Typography>
        )}
        <LoadingModal
          className={'loading'}
          isLoading={isLoading || inProgress !== InteractionStatus.None}
          message={isLoading ? "You are being redirected to your organization's login" : 'Loading...'}
        />
      </BoxWithCityBackground>
    </IndexPaperPageContent>
  );
};

export default Login;
