import { NavigatorActionType } from '../actions/enums';
import { NavigatorAction } from '../actions';
import { KeyStringBoolValue } from '../typings';

export interface NavigatorState {
  subMenu: KeyStringBoolValue;
  prevSubMenu: KeyStringBoolValue;
  isDrawerOpenDesktop: boolean;
  isDrawerOpenMobile: boolean;
  isPinnedMenuIcon: boolean;
}

export const defaultNavigatorState = {
  subMenu: {} as KeyStringBoolValue,
  prevSubMenu: {} as KeyStringBoolValue,
  isDrawerOpenDesktop: false,
  isDrawerOpenMobile: false,
  isPinnedMenuIcon: false
};

const navigatorReducer = (
  state: NavigatorState = defaultNavigatorState,
  action:
    | NavigatorAction
    | {
        type: '@@INIT';
      }
) => {
  switch (action.type) {
    case NavigatorActionType.SetSubMenu:
      //find the current submenu(module name) and reverse the previous value of the submenitem: EX Overview: false or Overview: true
      //This is then used to figure out whether to collapse or collapse-in the drawer submenu and how to display the open/close caret
      //EX: Overview: false - not opened. Overview: true - opened
      return { ...state, subMenu: { ...state.subMenu, [action.payload]: !state.subMenu[action.payload] } };
    case NavigatorActionType.setIsDrawerOpenDesktop:
      if (action.payload === state.isDrawerOpenDesktop) return state;
      if (state.isDrawerOpenDesktop) {
        return {
          ...state,
          isDrawerOpenDesktop: action.payload,
          prevSubMenu: state.subMenu,
          subMenu: {} as KeyStringBoolValue
        };
      }
      return { ...state, isDrawerOpenDesktop: action.payload, subMenu: state.prevSubMenu };
    case NavigatorActionType.setIsDrawerOpenMobile: //mobile drawer controls the desktop drawer as well. So if it closes, the desktop version will be closed, too.
      if (state.isDrawerOpenMobile) {
        return {
          ...state,
          isDrawerOpenMobile: !state.isDrawerOpenMobile,
          isDrawerOpenDesktop: !state.isDrawerOpenMobile,
          isPinnedMenuIcon: !state.isDrawerOpenMobile,
          prevSubMenu: state.subMenu,
          subMenu: {} as KeyStringBoolValue
        };
      }
      return {
        ...state,
        isDrawerOpenMobile: !state.isDrawerOpenMobile,
        isDrawerOpenDesktop: !state.isDrawerOpenMobile,
        isPinnedMenuIcon: !state.isDrawerOpenMobile,
        subMenu: state.prevSubMenu
      };
    case NavigatorActionType.setIsPinnedMenuIcon:
      return { ...state, isPinnedMenuIcon: action.payload };
    default:
      return state;
  }
};

export default navigatorReducer;
