import { Typography, TypographyProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import AssignableComponent from '../interfaces/AssignableComponent';

interface Props extends AssignableComponent, TypographyProps {}

/**
 * A styled Typography component that can be used for Page headings.
 * - centers itself on the screen once the sdreen reaches a small breakpoint
 *
 * Props:
 * - component: An optional MUI prop that creates text within a specific component
 * - other props are MUI Typography specific
 * - more on typography MUI component can be found [here](https://mui.com/components/typography/)
 */
const PageContentHeading = styled(Typography)<Props>(({ theme }) => ({
  flex: '1 1 100%',
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  margin: theme.spacing(1)
}));

export default PageContentHeading;
