import ReducerDefinition from '../../../interfaces/ReducerDefinition';
import { ClientAdminIntegrationsActionType } from '../actions/enums';
import { ReduxInitAction } from '../../../typings';
import IntegrationModel from '../models/IntegrationModel';
import { ClientAdminIntegrationsAction } from '../actions/clientAdminIntegrations';
import IntegrationEndpoint from '../models/IntegrationEndpoint';

export interface ClientAdminIntegrationsState {
  isFetching: boolean;
  integrations: IntegrationModel[];
  integrationEndpoints: IntegrationEndpoint[];
  orgId: string;
  fetchError: any;
}

export const defaultClientAdminIntegrationsState: ClientAdminIntegrationsState = {
  isFetching: false,
  integrations: [],
  integrationEndpoints: [] as IntegrationEndpoint[],
  orgId: '',
  fetchError: ''
};

const clientAdminIntegrationsReducer: ReducerDefinition = {
  reducerName: 'integrations',
  reducerFunction: (
    state: ClientAdminIntegrationsState = defaultClientAdminIntegrationsState,
    action: ClientAdminIntegrationsAction | ReduxInitAction
  ): ClientAdminIntegrationsState => {
    switch (action.type) {
      case ClientAdminIntegrationsActionType.FetchInit:
        return {
          ...state,
          isFetching: true
        } as ClientAdminIntegrationsState;
      case ClientAdminIntegrationsActionType.FetchSuccess:
        return {
          ...state,
          isFetching: false,
          integrations: action.payload
        } as ClientAdminIntegrationsState;
      case ClientAdminIntegrationsActionType.FetchError:
        return {
          ...state,
          isFetching: false,
          fetchError: action.payload
        } as ClientAdminIntegrationsState;
      default:
        return state;
    }
  }
};

export default clientAdminIntegrationsReducer;
