export const isEmpty = (object: any): boolean => {
  for (const _ in object) {
    return false;
  }
  return true;
};

export class Sort<T> {
  ByProperty(items: T[], property: keyof T) {
    return items.sort((a, b) => {
      const stringA = a[property];
      const stringB = b[property];

      let result: number;
      if (stringA < stringB) {
        result = -1;
      } else if (stringA > stringB) {
        result = 1;
      } else {
        result = 0;
      }

      return result;
    });
  }
}

export const isJsonString = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const isValidEmail = (email: string) => {
  return email && /.+@.+\..+/.test(email);
};

export const getLastSegmentOfUrl = (url: string): string => {
  // Remove any query string
  let urlWithoutQuery = url.split('?')[0];

  // Split the URL by slashes and get the last part
  let segments = urlWithoutQuery.split('/');
  return segments.pop() ?? ''; // Returns the last segment
};

export function isValidGuid(guid: string): boolean {
  const regex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
  return regex.test(guid);
}
