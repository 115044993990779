export enum NavigatorActionType {
  SetSubMenu = 'NAVIGATOR_SET_SUB_MENU',
  setIsDrawerOpenDesktop = 'NAVIGATOR_SET_IS_DRAWER_OPEN_DESKTOP',
  setIsDrawerOpenMobile = 'NAVIGATOR_SET_IS_DRAWER_OPEN_MOBILE',
  setIsPinnedMenuIcon = 'NAVIGATOR_SET_IS_PINNED_MENU_ICON'
}

export enum AlertActionType {
  SetAlert = 'ALERT_SET_ALERT',
  ClearAlert = 'ALERT_CLEAR_ALERT'
}
