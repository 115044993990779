import { Collapse, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import { navClasses } from './Navigator';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { AccountInfoType, selectIsCompanyTenant } from '../../auth/AccountInfoSlice';
import { setSubMenu } from '../../actions';
import { ThunkDispatch } from 'redux-thunk';
import { connect, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { NavigatorState } from '../../reducers/navigator';
import { removeWhiteSpaces } from '../../utils/regex';
import { canViewBasedOnRequiredChecks } from '../../utils/visibility';
import Module, { ModuleSubPath } from '../../models/module';
import DynamicallyGeneratedMenuItems from './DynamicallyGeneratedMenuItems';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

export interface NavigatorNestedSubMenuProps {
  module: Module;
  onListItemClick: (module: Module, path?: ModuleSubPath) => void;
  pathName: string;
  accountInfo: AccountInfoType;
  navigatorState: NavigatorState;
  setSubMenu: typeof setSubMenu;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export const NavigatorNestedListItem: React.FunctionComponent<NavigatorNestedSubMenuProps> = (props: NavigatorNestedSubMenuProps) => {
  const { module, onListItemClick, pathName, accountInfo, navigatorState, setSubMenu } = props;
  //look at user info roles but also launch darkly flag to determine whether to render the sub menu item or not. Module level role access and launch darkly determination has already been made
  const ldFlags = useFlags();
  const isCompanyTenant = useSelector(selectIsCompanyTenant);
  //determine any active subpaths of the module based on user roles, whether it can only be viewed by the company org and the feature flag setting
  const activeSubPaths = module.mainPaths?.map(mainPath =>
    canViewBasedOnRequiredChecks(accountInfo, ldFlags, { ...mainPath.permissions }, isCompanyTenant)
  );

  const handleNestedMenuClick = (moduleName: string) => {
    setSubMenu(moduleName);
  };

  const selected = (module: Module, path?: ModuleSubPath) => {
    if (path?.param?.length) {
      const insertedParam = path.url.split(':')[0] + path.param;
      return pathName.includes(`${module.path}/${insertedParam}`);
    } else if (path) {
      return pathName.includes(`${module.path}/${path.url}`);
    } else if (pathName.startsWith('/reports')) {
      const reportType = pathName?.split('/')[2];
      if (reportType) {
        return `/${reportType}` === module.path;
      }
    } else {
      const reportType = pathName?.split('/')[1];
      return `/${reportType}` === module.path;
    }
  };

  function renderSubListItem(module: Module, path: ModuleSubPath): React.ReactNode {
    if (canViewBasedOnRequiredChecks(accountInfo, ldFlags, { ...path.permissions }, isCompanyTenant) && !path.hideFromMenu) {
      return (
        <ListItemButton
          key={path.name}
          id={`${removeWhiteSpaces(module?.name)}-${removeWhiteSpaces(path.name)}`}
          component="li"
          selected={selected(module, path)}
          onClick={() => {
            onListItemClick(module, path);
          }}
          className={clsx(navClasses.nestedListItem, navClasses.item, selected(module, path) ? navClasses.itemActiveItem : '')}
        >
          <ListItemIcon>{path?.icon}</ListItemIcon>
          <ListItemText primary={path.name} />
        </ListItemButton>
      );
    }
    return null;
  }

  const generateMenuItem = (module: Module) => {
    return (
      <React.Fragment>
        <ListItemButton
          key={module?.name}
          component="li"
          id={removeWhiteSpaces(module?.name)}
          onClick={() => {
            handleNestedMenuClick(module?.name);
          }}
          className={clsx(navClasses.item, navClasses.listItem, selected(module) ? navClasses.itemActiveItem : '')}
        >
          <ListItemIcon>{module.icon}</ListItemIcon>
          <ListItemText primary={module?.name} />
          {navigatorState.subMenu[module?.name] ? (
            <RemoveIcon id={`${removeWhiteSpaces(module?.name)}-nav-menu-expand-less`} />
          ) : (
            <AddIcon id={`${removeWhiteSpaces(module?.name)}-nav-menu-expand-more`} />
          )}
        </ListItemButton>
        <Collapse in={navigatorState.subMenu[module?.name]} timeout="auto" unmountOnExit>
          <List disablePadding>
            {[
              ...(module.mainPaths?.map(path => renderSubListItem(module, path)) ?? []),
              ...(module.dynamicallyGeneratedMenus?.length
                ? [
                    <DynamicallyGeneratedMenuItems
                      key="dynamicallyGeneratedMenus"
                      modules={module.dynamicallyGeneratedMenus}
                      accountInfo={accountInfo}
                      pathName={pathName}
                      onListItemClick={onListItemClick}
                    />
                  ]
                : [])
            ]}
          </List>
        </Collapse>
      </React.Fragment>
    );
  };

  //if any of the submodule paths are active - render the submenu and the submenu paths
  if (activeSubPaths?.some(active => active) || module.dynamicallyGeneratedMenus?.length) {
    return generateMenuItem(module);
  }
  return null;
};

const mapStateToProps = (state: any) => {
  return {
    navigatorState: state.navigator
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, never, any> | Dispatch<any>) => ({
  setSubMenu: (subMenu: string) => dispatch(setSubMenu(subMenu))
});

export default connect(mapStateToProps, mapDispatchToProps)(NavigatorNestedListItem);
