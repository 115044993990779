import Module from '../../models/module';
import setupModuleReducers from '../../utils/setupModuleReducers';
import { Business } from '@mui/icons-material';
import { lazyMinLoadTime } from '../../utils/codeSplitting';
import FeatureFlags from '../../launchDarkly/featureFlags';
import clientAdminReducer from './reducers/clientAdmin';
import clientAdminTenantReducer from './reducers/clientAdminTenant';
import { mainPaths } from './components';
import clientAdminIntegrationsReducer from './reducers/clientAdminIntegrations';
import clientAdminTenantIntegrationsReducer from './reducers/clientAdminTenantIntegrations';
import clientAdminIntegrationTenantsReducer from './reducers/clientAdminIntegrationTenants';
import clientAdminTenantSFTPConfigurationReducer from './reducers/clientAdminTenantSFTPConfiguration';
import clientAdminTenantSFTPConnectionReducer from './reducers/clientAdminTenantSFTPConnection';
import clientAdminTenantSFTPGpgReducer from './reducers/clientAdminTenantSFTPGpg';
import clientAdminTenantSalesforceConfigurationReducer from './reducers/clientAdminTenantSalesforceConfiguration';
import clientAdminIntegrationEndpointsReducer from './reducers/clientAdminIntegrationEndpoints';
import clientAdminTenantTicketmasterConfigurationReducer from './reducers/clientAdminTenantTicketmasterConfiguration';
import Roles from '../../rbac/roles';

const modulePrefix = 'clientAdmin_';
const ClientAdminModule = new Module({
  name: 'Client Admin',
  path: '/client-admin',
  modulePrefix: modulePrefix,
  component: lazyMinLoadTime(() => import('./components')),
  icon: <Business />,
  reducers: setupModuleReducers(
    [
      clientAdminReducer,
      clientAdminTenantReducer,
      clientAdminIntegrationsReducer,
      clientAdminTenantIntegrationsReducer,
      clientAdminIntegrationTenantsReducer,
      clientAdminTenantSFTPConfigurationReducer,
      clientAdminTenantSFTPConnectionReducer,
      clientAdminTenantSFTPGpgReducer,
      clientAdminTenantSalesforceConfigurationReducer,
      clientAdminIntegrationEndpointsReducer,
      clientAdminTenantTicketmasterConfigurationReducer
    ],
    modulePrefix
  ), //add any additional reducers here as needed
  permissions: {
    roles: [Roles.GlobalAdmin, Roles.ReportManager],
    ldFlag: FeatureFlags.ClientAdmin,
    isCompanyOrgOnly: true
  },
  mainPaths: mainPaths
});

export default ClientAdminModule;
