import { AlertActionType } from './enums';
import { AlertModel } from '../models/AlertModel';

export interface AlertAction {
  type: AlertActionType;
  payload?: any;
}

export const alertSetAlert = (payload: AlertModel): AlertAction => ({
  type: AlertActionType.SetAlert,
  payload
});

export const alertClearAlert = (): AlertAction => ({
  type: AlertActionType.ClearAlert
});
