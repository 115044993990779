import ReducerDefinition from '../../../interfaces/ReducerDefinition';
import { ReduxInitAction } from '../../../typings';
import { ClientAdminTenantSFTPGpgAction } from '../actions/clientAdminTenantSFTPGpg';
import { ClientAdminTenantSFTPGpgActionType } from '../actions/enums';
import GpgConfiguration from '../models/GpgConfiguration';
import { GpgKeyType } from '../models/enums/GpgKeyType';

export interface ClientAdminTenantSFTPGpgState {
  isFetching: boolean;
  isLoading: boolean;
  fetchError: any;
  mutationError: any;
  showAddNew: boolean;
  tenantGpgs: GpgConfiguration[];
  gpg: GpgConfiguration;
  validationErrors: any;
}

export const defaultClientAdminTenantSFTPGpgState: ClientAdminTenantSFTPGpgState = {
  isFetching: false,
  isLoading: false,
  fetchError: '',
  mutationError: '',
  showAddNew: false,
  tenantGpgs: [],
  gpg: { id: '', tenantId: '', gpgKeyName: '', keyType: GpgKeyType.PRIVATE, key: '', passphrase: '', active: true },
  validationErrors: {}
};

const clientAdminTenantSFTPGpgReducer: ReducerDefinition = {
  reducerName: 'tenant_sftp_gpg',
  reducerFunction: (
    state: ClientAdminTenantSFTPGpgState = defaultClientAdminTenantSFTPGpgState,
    action: ClientAdminTenantSFTPGpgAction | ReduxInitAction
  ): ClientAdminTenantSFTPGpgState => {
    switch (action.type) {
      case ClientAdminTenantSFTPGpgActionType.IsFetching:
        return {
          ...state,
          isFetching: true
        } as ClientAdminTenantSFTPGpgState;
      case ClientAdminTenantSFTPGpgActionType.IsLoading:
        return {
          ...state,
          isLoading: true
        } as ClientAdminTenantSFTPGpgState;
      case ClientAdminTenantSFTPGpgActionType.FetchOneSuccess:
        return {
          ...state,
          gpg: action.payload
        } as ClientAdminTenantSFTPGpgState;
      case ClientAdminTenantSFTPGpgActionType.FetchAllSuccess:
        return {
          ...state,
          tenantGpgs: action.payload
        } as ClientAdminTenantSFTPGpgState;
      case ClientAdminTenantSFTPGpgActionType.FetchError:
        return {
          ...state,
          fetchError: action.payload,
          isFetching: false
        } as ClientAdminTenantSFTPGpgState;
      case ClientAdminTenantSFTPGpgActionType.MutationError:
        return {
          ...state,
          mutationError: action.payload
        };
      case ClientAdminTenantSFTPGpgActionType.OnChange:
        const { key, value } = action.payload as { key: keyof GpgConfiguration; value: any };
        return {
          ...state,
          gpg: { ...state.gpg, [key]: value }
        } as ClientAdminTenantSFTPGpgState;
      case ClientAdminTenantSFTPGpgActionType.SaveSuccess:
        return {
          ...state,
          gpg: action.payload,
          isLoading: false,
          validationErrors: {}
        } as ClientAdminTenantSFTPGpgState;
      case ClientAdminTenantSFTPGpgActionType.DeleteSuccess:
        return {
          ...state,
          gpg: { ...state.gpg, active: false } as GpgConfiguration,
          isLoading: false,
          validationErrors: {}
        } as ClientAdminTenantSFTPGpgState;
      case ClientAdminTenantSFTPGpgActionType.ToggleShowAddNew:
        return {
          ...state,
          showAddNew: action.payload
        };
      case ClientAdminTenantSFTPGpgActionType.SetValidationErrors:
        return {
          ...state,
          validationErrors: action.payload,
          isLoading: false
        };
      case ClientAdminTenantSFTPGpgActionType.ResetState:
        return { ...defaultClientAdminTenantSFTPGpgState };
      default:
        return state;
    }
  }
};

export default clientAdminTenantSFTPGpgReducer;
