import React from 'react';
import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import { Box, Typography, useScrollTrigger } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CSSProperties } from '@mui/styled-engine';
import { ThunkDispatch } from 'redux-thunk';
import { Dispatch } from 'redux';
import { setIsDrawerOpenMobile } from '../actions';
import { connect, useSelector } from 'react-redux';
import AccountMenu from './AccountMenu';
import { selectTenantName } from '../auth/AccountInfoSlice';

const StyledMenuButton = styled(IconButton)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  marginTop: theme.spacing(1)
}));

const StyledDivAppBarOffset = styled('div')(({ theme }) => ({
  ...(theme.mixins.toolbar as CSSProperties)
}));

interface AppBarElevationProps {
  children: React.ReactElement;
}

//This component renders the AppBar as a child and updates the elevation prop when content goes under the app bar which makes it known to the user they're not at the top of the page
function AppBarElevation(props: AppBarElevationProps) {
  const { children } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0
  });
}

interface HeaderProps {
  onDrawerToggleMobile: typeof setIsDrawerOpenMobile;
}

export function Header(props: HeaderProps) {
  const { onDrawerToggleMobile } = props;
  const tenantName = useSelector(selectTenantName);

  return (
    <React.Fragment>
      <AppBarElevation {...props}>
        <AppBar color="primary" elevation={0}>
          <Toolbar>
            <Grid container spacing={1} alignItems="center" justifyContent="flex-end">
              <Box component="div" sx={{ display: { md: 'none', xs: 'block' } }}>
                <Grid item>
                  <StyledMenuButton color="inherit" aria-label="open drawer" onClick={onDrawerToggleMobile}>
                    <MenuIcon />
                  </StyledMenuButton>
                </Grid>
              </Box>
              <Grid item xs />
              {tenantName && (
                <Grid item>
                  <Typography>{tenantName}</Typography>
                </Grid>
              )}
              <Grid item>
                <AccountMenu />
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </AppBarElevation>
      <StyledDivAppBarOffset />
    </React.Fragment>
  );
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, never, any> | Dispatch<any>) => ({
  onDrawerToggleMobile: () => dispatch(setIsDrawerOpenMobile())
});

export default connect(undefined, mapDispatchToProps)(Header);
