import Module, { ModuleSubPath } from '../../../models/module';
import { lazyMinLoadTime } from '../../../utils/codeSplitting';
import FeatureModel from '../../user-admin/models/FeatureModel';
import Features from '../../../rbac/features';
import FeatureFlags from '../../../launchDarkly/featureFlags';

const componentMap: any = {
  tableau: () => import('./Tableau'),
  retool: () => import('./Retool')
};

const getComponent = (subType: string) => {
  const loader = componentMap[subType?.toLowerCase()];
  if (loader) {
    return lazyMinLoadTime(loader);
  } else {
    // Fall back to a default
    return lazyMinLoadTime(() => import('./ErrorState'));
  }
};

export const moduleBuilder = (featurePermissions: Features[], ldFlag: string, reports: FeatureModel[] = []) => {
  //Group reports by subcategory, so they can live under their respective menus
  const reportsGroupedBySubCategory = reports.reduce((acc: any, obj: FeatureModel) => {
    if (obj.menuSubCategory === null || obj.menuSubCategory === undefined || obj.menuSubCategory === '') {
      acc['Uncategorized'] = acc['Uncategorized'] || [];
      acc['Uncategorized'].push(obj);
    } else {
      acc[obj.menuSubCategory] = acc[obj.menuSubCategory] || [];
      acc[obj.menuSubCategory].push(obj);
    }
    return acc;
  }, {});

  //map over each group of subnav items and create a module(menuItem) from the folder name and subpaths from respective links
  const modules = Object.values(reportsGroupedBySubCategory).map((features: any): Module => {
    const { menuCategory, menuSubCategory } = features[0];
    let path = menuCategory;
    if (menuSubCategory?.length) {
      path += `/${menuSubCategory}`;
    }
    path = path.toLowerCase().split(' ').join('-');
    const mainPaths = (
      features.map((feature: FeatureModel): ModuleSubPath => {
        const { name, id } = feature;
        const url = name.toLowerCase().split(' ').join('-');
        const key = id;
        return {
          key,
          url: `${url}/${id}`,
          name: name,
          permissions: {
            features: featurePermissions,
            ldFlag: FeatureFlags.ReportingModule,
            isCompanyOrgOnly: false
          },
          component: getComponent(feature.subType?.toLowerCase()),
          exact: true,
          hideFromMenu: false
        };
      }) || ([] as ModuleSubPath[])
    ).sort((a: ModuleSubPath, b: ModuleSubPath) => a.name.localeCompare(b.name));

    return new Module({
      name: features[0].menuSubCategory || '',
      path: `/reports/${path}`,
      modulePrefix: `${path}_`,
      component: lazyMinLoadTime(() => import('./index')),
      icon: undefined,
      permissions: {
        features: featurePermissions,
        ldFlag: ldFlag,
        isCompanyOrgOnly: false
      },
      mainPaths
    });
  });
  return modules.sort((a: Module, b: Module) => a.name.localeCompare(b.name));
};
