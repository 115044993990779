import { toast } from 'react-toastify';
import theme from '../theme';

const ErrorToast = (message: string, error: string) => {
  toast.error(message + error, {
    position: toast.POSITION.BOTTOM_RIGHT,
    style: { backgroundColor: theme.palette.error.main, fontFamily: theme.typography.fontFamily }
  });
};

const WarningToast = (message: string) => {
  toast.warning(message, {
    position: toast.POSITION.BOTTOM_RIGHT,
    style: { backgroundColor: theme.palette.warning.main, fontFamily: theme.typography.fontFamily }
  });
};

const SuccessToast = (message: string) => {
  toast.success(message, {
    position: toast.POSITION.BOTTOM_RIGHT,
    style: { backgroundColor: theme.palette.success.main, fontFamily: theme.typography.fontFamily }
  });
};

const CopySuccessToast = (message: string) => {
  toast.success(message, {
    position: toast.POSITION.TOP_RIGHT
  });
};

export { ErrorToast, WarningToast, SuccessToast, CopySuccessToast };
