import { api } from '../../../shared/api';
import SavedTenantModel from '../models/SavedTenantModel';

export const ClientAdminTenantApiSlice = api.enhanceEndpoints({ addTagTypes: ['Tenant'] }).injectEndpoints({
  endpoints: builder => ({
    fetchAllTenants: builder.query<SavedTenantModel[], void>({
      query: () => `${process.env.REACT_APP_TENANT_METADATA_BASE_URL}/api/tenants`,
      providesTags: result =>
        result
          ? [
              { type: 'Tenant' as const, id: 'LIST' },
              ...result.map(({ id }) => ({
                type: 'Tenant' as const,
                id: id
              }))
            ]
          : [{ type: 'Tenant' as const, id: 'LIST' }],
      transformResponse: (response: SavedTenantModel[]) => {
        return response.sort((a, b) => a.name.localeCompare(b.name));
      }
    }),
    fetchTenantById: builder.query<SavedTenantModel, string>({
      query: id => `${process.env.REACT_APP_TENANT_METADATA_BASE_URL}/api/tenants/${id}`,
      providesTags: result =>
        result
          ? [
              { type: 'Tenant' as const, id: result.id },
              { type: 'Tenant' as const, id: 'LIST' }
            ]
          : [{ type: 'Tenant' as const, id: 'LIST' }]
    }),
    saveTenant: builder.mutation({
      query: payload => ({
        url: `${process.env.REACT_APP_TENANT_METADATA_BASE_URL}/api/tenants`,
        method: 'POST',
        body: payload
      }),
      invalidatesTags: [{ type: 'Tenant', id: 'LIST' }]
    }),
    updateTenant: builder.mutation({
      query: payload => ({
        url: `${process.env.REACT_APP_TENANT_METADATA_BASE_URL}/api/tenants`,
        method: 'PUT',
        body: payload
      }),
      invalidatesTags: [{ type: 'Tenant', id: 'LIST' }]
    }),
    deleteTenant: builder.mutation({
      query: id => ({
        url: `${process.env.REACT_APP_TENANT_METADATA_BASE_URL}/api/tenants/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: [{ type: 'Tenant', id: 'LIST' }]
    })
  })
});

export const { useFetchTenantByIdQuery, useFetchAllTenantsQuery, useSaveTenantMutation, useUpdateTenantMutation, useDeleteTenantMutation } =
  ClientAdminTenantApiSlice;
