import Module from '../../models/module';
import { LocalActivity } from '@mui/icons-material';
import { lazyMinLoadTime } from '../../utils/codeSplitting';
import { mainPaths } from './components';
import Roles from '../../rbac/roles';
import Features from '../../rbac/features';
import FeatureFlags from '../../launchDarkly/featureFlags';

const modulePrefix = 'ticket-tagging_';
const TicketTaggingModule = new Module({
  name: 'Ticket Tagging 2.0',
  path: '/ticket-tagging',
  modulePrefix: modulePrefix,
  component: lazyMinLoadTime(() => import('./components')),
  icon: <LocalActivity />,
  permissions: {
    ldFlag: FeatureFlags.TicketTagging,
    features: [Features.TicketTagging],
    roles: [Roles.Administrator, Roles.GlobalAdmin, Roles.TicketTagging],
    eitherRolesOrFeatures: true
  },
  mainPaths: mainPaths
});

export default TicketTaggingModule;
