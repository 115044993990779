import { configureStore, EnhancedStore } from '@reduxjs/toolkit';
import { CombinedReducerDefinition } from '../typings';
import navigatorReducer, { defaultNavigatorState } from '../reducers/navigator';
import alertReducer from '../reducers/alert';
import accountInfoReducer from '../auth/AccountInfoSlice';
import modules from '../modules';
import SsbModule from '../models/module';
import { CurriedGetDefaultMiddleware } from '@reduxjs/toolkit/src/getDefaultMiddleware';
import { api } from '../shared/api';

const combinedReducers: CombinedReducerDefinition = {
  navigator: navigatorReducer,
  alert: alertReducer,
  accountInfo: accountInfoReducer,
  [api.reducerPath]: api.reducer
}; //add main app reducers here

//each module can have one or more reducers.
//Go through each module, collect all reducers and assign them to the combinedReducers object
modules.forEach((module: SsbModule): void => {
  Object.assign(combinedReducers, module.reducers);
});

const configureAppStore: () => EnhancedStore = () => {
  const savedNavigatorState = localStorage.getItem('navigatorState');
  const initialNavigatorState = savedNavigatorState ? JSON.parse(savedNavigatorState) : defaultNavigatorState;
  const store = configureStore({
    reducer: combinedReducers,
    middleware: (getDefaultMiddleware: CurriedGetDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware),
    devTools: process.env.NODE_ENV !== 'production',
    preloadedState: { navigator: initialNavigatorState }
  });

  store.subscribe(() => {
    const navigatorState = store.getState().navigator;
    localStorage.setItem('navigatorState', JSON.stringify(navigatorState));
  });

  return store;
};

export default configureAppStore;
