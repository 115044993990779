import { AccountInfoType } from '../auth/AccountInfoSlice';

export const trackUser = (accountInfo: AccountInfoType) => {
  // This function creates anonymous visitor IDs in Pendo unless you change the visitor id field to use your app's values
  // This function uses the placeholder 'ACCOUNT-UNIQUE-ID' value for account ID unless you change the account id field to use your app's values
  // Call this function in your authentication promise handler or callback when your visitor and account id values are available
  // Please use Strings, Numbers, or Bools for value types.
  const pendoVisitor: any = {
    visitor: {
      id: accountInfo?.sub ?? 'ACCOUNT-UNIQUE-ID', // Required if user is logged in, default creates anonymous ID
      email: accountInfo?.email ?? '', // Recommended if using Pendo Feedback, or NPS Email
      full_name: accountInfo?.name ?? '', // Recommended if using Pendo Feedback
      tenant_id: accountInfo?.tenantId ?? '', // Optional
      tenant_name: accountInfo?.tenantName ?? '', // Optional
      roles: accountInfo?.roles ?? ''
      // You can add any additional visitor level key-values here,
      // as long as it's not one of the above reserved names.
    },
    account: {
      id: accountInfo?.tenantId ?? 'ACCOUNT-UNIQUE-ID', // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
      name: accountInfo?.tenantName ?? '' // Optional
    }
  };

  pendo.identify(pendoVisitor);
};

export const trackPendoEvent = (eventName: string, metadata: any) => {
  pendo.track(eventName, metadata);
};
