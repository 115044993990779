import { currentTenantIdLocalStorageKey } from './org';

export const setCurrentTenantIdToSessionStorage = (tenantId: string) => {
  sessionStorage.setItem(currentTenantIdLocalStorageKey, tenantId);
};

export const removeCurrentTenantIdFromSessionStorage = () => {
  sessionStorage.removeItem(currentTenantIdLocalStorageKey);
};

export const getCurrentTenantIdFromSessionStorage = () => sessionStorage.getItem(currentTenantIdLocalStorageKey);
