import { lazy, ComponentType } from 'react';

/**
 * A lazy load util function that usees a promise/all to lazy load the component and waits at least 300 miliseconds in parallel by default to avoid screen flashes on a very fast network, if the component takes longer than that, then it waits for it to load past the specified time
 *
 * Params:
 * @param minLoadTimeInMs  - provides a different value for minimum wait time, default of `300` milliseconds
 */
export const lazyMinLoadTime = <T extends ComponentType<any>>(factory: () => Promise<{ default: T }>, minLoadTimeInMs = 300) =>
  lazy(() => Promise.all([factory(), new Promise(resolve => setTimeout(resolve, minLoadTimeInMs))]).then(([moduleExport]) => moduleExport));
