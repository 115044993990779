import { NavigatorActionType } from './enums';

export interface NavigatorAction {
  type: NavigatorActionType;
  payload?: any;
}

export const setSubMenu = (subMenu: string): NavigatorAction => ({ type: NavigatorActionType.SetSubMenu, payload: subMenu });

export const setIsDrawerOpenDesktop = (isOpen: boolean): NavigatorAction => ({ type: NavigatorActionType.setIsDrawerOpenDesktop, payload: isOpen });

export const setIsPinnedMenuIcon = (isOpen: boolean): NavigatorAction => ({ type: NavigatorActionType.setIsPinnedMenuIcon, payload: isOpen });

export const setIsDrawerOpenMobile = (): NavigatorAction => ({ type: NavigatorActionType.setIsDrawerOpenMobile });
