import Module from '../../models/module';
import setupModuleReducers from '../../utils/setupModuleReducers';
import { Person } from '@mui/icons-material';
import { lazyMinLoadTime } from '../../utils/codeSplitting';
import { mainPaths } from './components';
import FeatureFlags from '../../launchDarkly/featureFlags';
import singleSignOnReducer from './reducers/singleSignOn';
import { userAdminRolesReducer } from './reducers/userAdminRoles';
import Roles from '../../rbac/roles';

const modulePrefix = 'administration_';
const UserAdminModule = new Module({
  name: 'Administration',
  path: '/administration',
  modulePrefix: modulePrefix,
  component: lazyMinLoadTime(() => import('./components')),
  icon: <Person />,
  reducers: setupModuleReducers([singleSignOnReducer, userAdminRolesReducer], modulePrefix), //add any additional reducers here as needed
  permissions: {
    ldFlag: FeatureFlags.UserAdmin,
    features: [],
    roles: [Roles.Administrator, Roles.GlobalAdmin]
  },
  mainPaths: mainPaths
});

export default UserAdminModule;
