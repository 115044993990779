import { Box, CircularProgress, Modal, Typography } from '@mui/material';
import React from 'react';

interface LoadingModalProps {
  isLoading: boolean;
  message: string;
  className: string;
}

export const LoadingModal: React.FunctionComponent<LoadingModalProps> = ({ isLoading, message, className }: LoadingModalProps) => {
  return (
    <Modal open={isLoading} aria-labelledby={className} className={className}>
      <Box height={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
        <Box display={'flex'} sx={{ backgroundColor: 'primary.dark', borderRadius: '4px' }} p={2} alignItems={'center'} gap={2}>
          <Typography variant={'body1'} color={'white'} className={'loader-text'}>
            {message}
          </Typography>
          <CircularProgress />
        </Box>
      </Box>
    </Modal>
  );
};
