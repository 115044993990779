import { createSlice } from '@reduxjs/toolkit';
import RoleModel from '../models/RoleModel';
import ReducerDefinition from '../../../interfaces/ReducerDefinition';
import { KeyofStringAnyValue } from '../../../typings';
import { UserModel } from '../../../models/UserModels';

export interface UserAdminRolesState {
  roles: RoleModel[];
  newRole: RoleModel;
  tenantId: string;
  showRoleManagementModal: boolean;
  roleUsers: UserModel[];
}

export const defaultUserAdminRoleState: UserAdminRolesState = {
  roles: [],
  newRole: {
    id: '',
    name: '',
    description: '',
    tenantId: '',
    featureIds: [],
    isSystem: false
  } as RoleModel,
  tenantId: '',
  showRoleManagementModal: false,
  roleUsers: []
};

export const userAdminRolesSlice = createSlice({
  name: 'userAdminRoles',
  initialState: defaultUserAdminRoleState,
  reducers: {
    selectRole: (state, action) => {
      state.newRole = action.payload;
    },
    onChange: (state, action) => {
      const { key, value } = action.payload as KeyofStringAnyValue<RoleModel>;
      state.newRole = { ...state.newRole, [key]: value };
    },
    showRoleManagementModal: state => {
      state.showRoleManagementModal = true;
    },
    closeRoleManagementModal: state => {
      state.showRoleManagementModal = false;
    },
    resetRole: state => {
      state.newRole = defaultUserAdminRoleState.newRole;
    },
    addFeature: (state, action) => {
      state.newRole.featureIds = state.newRole.featureIds?.length ? [...state.newRole.featureIds, action.payload] : [action.payload];
    },
    batchAddFeatures: (state, action) => {
      const remainingFeatureIdsToAdd = action.payload.filter((id: string) => !state.newRole.featureIds?.includes(id));
      state.newRole.featureIds = [...(state.newRole.featureIds ?? []), ...remainingFeatureIdsToAdd];
    },
    removeFeature: (state, action) => {
      state.newRole.featureIds = state.newRole.featureIds?.filter(id => id !== action.payload);
    },
    batchRemoveFeatures: (state, action) => {
      state.newRole.featureIds = state.newRole.featureIds?.filter(id => !action.payload.includes(id));
    }
  }
});

export const userAdminRolesReducer: ReducerDefinition = {
  reducerName: userAdminRolesSlice.name,
  reducerFunction: userAdminRolesSlice.reducer
};
export const {
  selectRole,
  onChange,
  showRoleManagementModal,
  closeRoleManagementModal,
  resetRole,
  addFeature,
  batchAddFeatures,
  removeFeature,
  batchRemoveFeatures
} = userAdminRolesSlice.actions;
export default userAdminRolesSlice;
