import Module from '../models/module';
import ClientAdmin from './client-admin';
import UserAdmin from './user-admin';
import Home from './home';
import Segmentation from './segmentation';
import { CustomReportsModule, GRMModule, MonitoringModule, OpsManagementModule, ReportsModule, ToolsModule } from './reporting';
import TicketTagging from './ticket-tagging';

//Add imported modules to this array
const Modules: Module[] = [
  Home,
  UserAdmin,
  ClientAdmin,
  ReportsModule,
  CustomReportsModule,
  GRMModule,
  Segmentation,
  MonitoringModule,
  OpsManagementModule,
  ToolsModule,
  TicketTagging
];
export default Modules;
