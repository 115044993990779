import React, { Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Navigator from './navigation/Navigator';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { css, Global } from '@emotion/react';
import Header from './Header';
import modules from '../modules';
import history from '../history';
import {
  AccountInfoType,
  selectAccounts,
  selectCurrentAccount,
  selectFeatures,
  selectIsCompanyTenant,
  selectIsGlobalAdmin,
  setAccounts,
  setCurrentAccount,
  setCurrentTenantName,
  setFeatures
} from '../auth/AccountInfoSlice';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import Module from '../models/module';
import { setIsDrawerOpenMobile } from '../actions';
import { connect, useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Dispatch } from 'redux';
import { NavigatorState } from '../reducers/navigator';
import { Box, Theme } from '@mui/material';
import { styled, ThemeProvider, useTheme } from '@mui/material/styles';
import Loading from './navigation/Loading';
import { canViewBasedOnRequiredChecks } from '../utils/visibility';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorBoundaryFallback from './ErrorBoundaryFallback';
import AccessDenied from '../pages/AccessDenied';
import { useFetchAllTenantFeaturesQuery, useFetchTenantUserReportsQuery } from '../modules/client-admin/api/ClientAdminFeatureApiSlice';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import { generateDynamicMenusFromModules } from '../utils/generateDynamicMenusFromModules';
import { isEmpty, isValidGuid } from '../utils/helpers';
import { useAuthorizeUserQuery } from '../auth/UserApiSlice';
import { AuthUserModel } from '../models/UserModels';
import { useMsal } from '@azure/msal-react';
import { trackUser } from '../utils/pendo';
import withAITrackingComponent from '../utils/appInsights';
import { useFetchAllTenantsQuery } from '../modules/client-admin/api/ClientAdminTenantApiSlice';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Roles from '../rbac/roles';
import { getCurrentTenantIdFromSessionStorage } from '../utils/sessionStorage';

//use router below instead of browser router, so we can provide our own exported history instead. Lets us use history from outside of components

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://affinaquest.com/" target="_blank" rel="noreferrer">
        Affinaquest
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export const drawerWidth = 400;

const globalStyles = css`
  html {
    font-size: 16px !important;
  }
`;

const StyledRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  minHeight: '100vh'
}));

const StyledApp = styled('div')(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto' //remove if needed (Added so the table on mobile doesn't get cutoff and actually has a horizontal scrolling)
}));

const StyledMain = styled('div', {
  shouldForwardProp: prop => prop !== 'isPinnedMenuIcon'
})(({ theme, isPinnedMenuIcon }: { theme: Theme; isPinnedMenuIcon: boolean }) => ({
  flex: 1,
  padding: theme.spacing(2, 2, 2, 2),
  background: '#eaeff1',
  [theme.breakpoints.up('md')]: {
    marginLeft: isPinnedMenuIcon ? drawerWidth : `calc(${theme.spacing(9)} + 1px)`
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0, 0)
  }
}));

const StyledFooter = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  background: '#eaeff1'
}));

export interface PaperBaseProps {
  navigatorState: NavigatorState;
  handleDrawerToggleMobile: typeof setIsDrawerOpenMobile;
}

export function Paperbase(props: PaperBaseProps) {
  const { navigatorState, handleDrawerToggleMobile } = props;
  const [externalProviderId, setExternalProviderId] = useState<string | undefined>(undefined);
  const isPinnedMenuIcon = navigatorState.isPinnedMenuIcon; // Add this line to extract the isPinnedMenuIcon prop
  const ldflags = useFlags();
  const theme = useTheme(); // Add this line to get the theme object
  const { accounts } = useMsal();
  const dispatch = useDispatch();
  const currentAccountInfo = useSelector(selectCurrentAccount);
  const currentAccount = useSelector(selectCurrentAccount);
  const { roles } = currentAccount;
  const userAccounts = useSelector(selectAccounts);
  const isCompanyTenant = useSelector(selectIsCompanyTenant);
  const { tenantId, sub } = currentAccountInfo;
  const features = useSelector(selectFeatures);
  const isGlobalAdmin = useSelector(selectIsGlobalAdmin);
  const { search } = useLocation();
  const tenantIdParam = new URLSearchParams(search).get('tenant');

  const { data: tenantFeatures } = useFetchAllTenantFeaturesQuery(tenantId || skipToken, {
    skip: !isGlobalAdmin && !roles?.includes(Roles.Administrator) && !tenantId
  });
  const skipFetchTenantUserReports = roles?.includes(Roles.GlobalAdmin) || roles?.includes(Roles.Administrator) || !tenantId || !sub;
  const { data: tenantUserFeature } = useFetchTenantUserReportsQuery(
    skipFetchTenantUserReports
      ? skipToken
      : {
          tenantId,
          userId: sub
        },
    {
      skip: skipFetchTenantUserReports
    }
  );
  const { data: tenants } = useFetchAllTenantsQuery();

  useEffect(() => {
    if ((roles?.includes(Roles.GlobalAdmin) || roles?.includes(Roles.Administrator)) && tenantFeatures) {
      dispatch(setFeatures(tenantFeatures));
    } else if (tenantUserFeature) {
      dispatch(setFeatures(tenantUserFeature));
    }
  }, [dispatch, roles, tenantFeatures, tenantUserFeature]);

  useEffect(() => {
    const tenantName = tenants?.find(tenant => tenant.id === currentAccountInfo.tenantId)?.name;
    tenantName && dispatch(setCurrentTenantName(tenantName));
  }, [currentAccountInfo.tenantId, dispatch, tenants]);

  useEffect(() => {
    if (accounts?.length) {
      setExternalProviderId(accounts[0].localAccountId);
    }
  }, [accounts]);

  useEffect(() => {
    if (currentAccount.sub?.length) {
      trackUser(currentAccount);
    }
  }, [currentAccount]);

  const { data: authorize } = useAuthorizeUserQuery(externalProviderId ?? skipToken);

  useEffect(() => {
    if (authorize?.length) {
      const buildUserAccounts: AccountInfoType[] = authorize.reduce((acc: AccountInfoType[], curr: AuthUserModel) => {
        if (tenants?.some(tenant => tenant.id === curr.tenantId)) {
          acc.push({
            roles: curr.roles ?? [],
            features: curr.permissions ?? [],
            permissions: curr.permissions ?? [],
            tenantId: curr.tenantId,
            sub: curr.id,
            tenantName: tenants?.find(tenant => tenant.id === curr.tenantId)?.name,
            email: curr.email,
            name: curr.firstName + ' ' + curr.lastName,
            picture: '',
            uuid: ''
          });
        }
        return acc;
      }, []);
      buildUserAccounts?.length && dispatch(setAccounts(buildUserAccounts));
    }
  }, [authorize, dispatch, tenants]);

  const appendTenantIdToSearchParams = useCallback(
    (searchParams: URLSearchParams, currentTenantId: string) => {
      const sessionStorageCurrentTenantId = getCurrentTenantIdFromSessionStorage();
      if (tenantIdParam && isValidGuid(tenantIdParam) && !currentTenantId) {
        searchParams.set('tenant', tenantIdParam);
        history.push({ search: searchParams.toString() });
      } else if (!tenantIdParam && currentTenantId) {
        searchParams.set('tenant', currentTenantId);
        history.push({ search: searchParams.toString() });
      } else if (!tenantIdParam && !currentTenantId && sessionStorageCurrentTenantId) {
        searchParams.set('tenant', sessionStorageCurrentTenantId);
        history.push({ search: searchParams.toString() });
      }
    },
    [tenantIdParam]
  );

  const handleTenantValidity = useCallback(
    (currentTenantId: string) => {
      if (tenantIdParam && userAccounts?.length) {
        if (isGlobalAdmin) {
          const tenantId = tenants?.find(tenant => tenant.id === tenantIdParam)?.id;
          if (!tenantId) {
            history.push('/access-denied');
          } else if (tenantId !== currentTenantId) {
            dispatch(setCurrentAccount(tenantId));
          }
        } else {
          const tenantId = userAccounts?.find((account: AccountInfoType) => account.tenantId === tenantIdParam)?.tenantId;
          if (!tenantId) {
            history.push('/access-denied');
          } else if (tenantId !== currentTenantId) {
            dispatch(setCurrentAccount(tenantId));
          }
        }
      }
    },
    [dispatch, isGlobalAdmin, tenantIdParam, tenants, userAccounts]
  );

  useEffect(() => {
    const { tenantId: currentTenantId } = currentAccount;
    let searchParams = new URLSearchParams(search);
    // Check if 'tenant' query parameter is present
    const hasTenantQuery = searchParams.has('tenant');
    // If there are multiple queries and none of them are 'tenant', add 'tenant'
    if (!hasTenantQuery) {
      appendTenantIdToSearchParams(searchParams, currentTenantId);
    }
    // Handle cases for valid and invalid tenantIdParam
    handleTenantValidity(currentTenantId);
  }, [
    currentAccount,
    currentAccount.tenantId,
    dispatch,
    tenantIdParam,
    isGlobalAdmin,
    tenants,
    userAccounts,
    userAccounts.length,
    search,
    handleTenantValidity,
    appendTenantIdToSearchParams
  ]);

  const modulesWithFeatures = useMemo(
    () => (modules: Module[]) => {
      if (!features || !currentAccount) return modules;
      return generateDynamicMenusFromModules(features, modules);
    },
    [features, currentAccount]
  );

  const ldClient = useLDClient();
  useEffect(() => {
    if (ldClient !== undefined && !isEmpty(currentAccountInfo)) {
      ldClient.identify({
        kind: 'user',
        name: currentAccountInfo.name,
        key: currentAccountInfo.sub,
        email: currentAccountInfo.email,
        custom: {
          tenant: currentAccountInfo.tenantId
        }
      });
    }
  }, [currentAccountInfo, ldClient]);

  //if module has a launch darkly flag and it's turned on in launch darkly or module doesn't have the flag, render the module. Otherwise, do not.
  const renderModule = useCallback(
    (module: Module) => {
      if (canViewBasedOnRequiredChecks(currentAccountInfo, ldflags, { ...module.permissions }, isCompanyTenant)) {
        return <Route {...module} key={module.name} />;
      }
      return null;
    },
    [currentAccountInfo, isCompanyTenant, ldflags]
  );

  //Setup a paperbase component with an ErrorBoundary wrapping around the main rendering in case an uncaught error occurs -- we don't render a white screen but allow the user to navigate back home
  //the errorboundary resets if history location changes by specifying the resetKeys
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={theme}>
        <Global styles={globalStyles} />
        <StyledRoot>
          {/* <CssBaseline /> */}
          <nav>
            <React.Fragment>
              <Box component="div" sx={{ display: { md: 'none', xs: 'block' } }}>
                <Navigator
                  PaperProps={{ style: { width: drawerWidth } }}
                  variant="temporary"
                  open={navigatorState.isDrawerOpenMobile}
                  onClose={handleDrawerToggleMobile}
                  isMobile={true}
                  modules={modulesWithFeatures(modules)}
                />
              </Box>
              <Box component="div" sx={{ display: { xs: 'none', md: 'block' } }}>
                <Navigator isMobile={false} modules={modulesWithFeatures(modules)} open={navigatorState.isDrawerOpenDesktop} />
              </Box>
            </React.Fragment>
          </nav>
          <StyledApp>
            <Header />
            <StyledMain theme={theme} isPinnedMenuIcon={isPinnedMenuIcon}>
              <ErrorBoundary FallbackComponent={ErrorBoundaryFallback} onReset={() => history.replace('/')} resetKeys={[history.location]}>
                <Suspense fallback={<Loading />}>
                  <Switch>
                    <Route exact path="/" render={() => <Redirect to="/home" />} />
                    {modulesWithFeatures(modules)?.map((module: any) => {
                      //If module has roles and the user roles match that of the module or if the module doesn't have roles
                      //And if module can only be viewed by the main/admin org and the current org is the main org, or if the module can be viewed by customer org as well
                      //try to render the module component
                      return renderModule(module);
                      //otherwise, do not render
                    })}
                  </Switch>
                  <Route path="/access-denied">
                    <AccessDenied />
                  </Route>
                </Suspense>
              </ErrorBoundary>
              <ToastContainer />
            </StyledMain>
            <StyledFooter>
              <Copyright />
            </StyledFooter>
          </StyledApp>
        </StyledRoot>
      </ThemeProvider>
    </LocalizationProvider>
  );
}

const mapStateToProps = (state: any) => {
  return {
    navigatorState: state.navigator
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, never, any> | Dispatch<any>) => ({
  handleDrawerToggleMobile: () => dispatch(setIsDrawerOpenMobile())
});

export default connect(mapStateToProps, mapDispatchToProps)(withAITrackingComponent(Paperbase));
