import React, { forwardRef } from 'react';
import { styled } from '@mui/material/styles';
import { Button, ButtonProps, CircularProgress } from '@mui/material';

interface ButtonWrapperProps extends ButtonProps {
  isWrapperMobileFullWidth?: boolean;
  isButtonMobileFullWidth?: boolean;
  isLoading?: boolean;
}

const ButtonWrapper = styled('div', { shouldForwardProp: prop => prop !== 'isWrapperMobileFullWidth' })<Partial<ButtonWrapperProps>>(
  ({ theme, isWrapperMobileFullWidth }) => ({
    position: 'relative',
    margin: theme.spacing(1), //so button has proper margins inside containers and matches the widths of the inputs we have
    [theme.breakpoints.down('sm')]: {
      ...(isWrapperMobileFullWidth && {
        width: '100%'
      })
    }
  })
);

//setup the ButtonWIthWrapper component as a Forwarding ref component, so the button can be rendered within the Grow animation component if need be, without failing
//and without the need of adding another wrapper div that can cause issues with the Button width
//the component passes the ref to the button from the GrowAnimation transition, hence the `forwardRef` wrapping when exporting
//more info on Grow API: https://mui.com/api/grow/#main-content

/**
 * Button with Wrapper component with the following props
 * - `fullWidth` - Optional: makes the button always take full width of its wrapper, no matter the window size
 * - `isWrapperMobileFullWidth` - Optional: works in conjuction with MUI Button's fullWidth prop to make sure the button takes full width of this wrapper, and the wrapper takes full width of the parent element.
 * Not allowing the button to overflow. Defaults to `false`
 * - `...buttonProps`
 *   - all possible props of the MUI button component
 */
export const ButtonWithWrapper: React.ForwardRefRenderFunction<any, ButtonWrapperProps> = (props: ButtonWrapperProps, ref: any) => {
  const { isWrapperMobileFullWidth, isButtonMobileFullWidth, fullWidth, isLoading, ...buttonProps } = props;
  return (
    <ButtonWrapper isWrapperMobileFullWidth={isWrapperMobileFullWidth}>
      <Button
        ref={ref}
        fullWidth={fullWidth || false}
        {...buttonProps}
        disabled={isLoading || buttonProps.disabled}
        startIcon={isLoading ? <CircularProgress color="inherit" size={16} /> : props.startIcon}
      >
        {props.children}
      </Button>
    </ButtonWrapper>
  );
};

export default forwardRef(ButtonWithWrapper);
