import React from 'react';
import { useLocation } from 'react-router-dom';

/**
 * useQuery hook that gets query parameters from route
 * - Returns the route with query parameters
 *
 */
export default function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}
